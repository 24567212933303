import { useState } from "react";
import { joinClassNames } from "../utils";

import "./styles.css";

type RadioButtonsProps<T> = {
  values: Array<{ value: T; view: string }>;
  currentValue: T | undefined;
  label?: string | React.ReactElement;
  onChange?: (value: T) => void;
  listName?: string;
  className?: string;
};

const RadioButtons = <T, T2 = null>(props: RadioButtonsProps<T>, _?: T2) => {
  const listName = props.listName || "list" + Date.now();
  const className = joinClassNames("RadioButtonsBlock FlexV", props.className);

  const [value, changeValue] = useState(props.currentValue);

  const onChange = (v: typeof props.values[number]) => () => {
    changeValue(v.value);
    props.onChange?.(v.value);
  };

  return (
    <div className={className}>
      {props.label}
      <div className="RadioButtonsValuesWrapper Centered">
        <div className="RadioButtonsValues FlexV">
          {props.values.map((v) => (
            <div
              className="ValueBlock FlexH CursorPointer"
              onClick={onChange(v)}
            >
              <input
                className="CursorPointer"
                type="radio"
                name={listName}
                checked={v.value === value}
              />

              <label className="PaddingLeft10 CursorPointer">{v.view}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RadioButtons;
