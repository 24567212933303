import { StatueId } from "./Statues/data";

const serverPath = "/pictures"

// "https://www.ozencalc.ru/pictures";

export const getPicturePath = (path: string, ext = "") => {
  return `${serverPath}/${path}${ext}`;
};

export const getGalleryPicturePath = (
  statueId: string,
  photo: string | number
) => {
  const photoWithExt = String(photo).includes(".") ? photo : photo + ".png";

  return serverPath + "/statues/" + statueId + "/" + photoWithExt;
};

export const getStatuePicturePaths = (path: string, ext = "jpeg") => {
  let [folderPath, files] = path
    .split("/")
    .map((v) => v.trim())
    .filter(Boolean);
  const folderParts = folderPath.split("-");
  const id = folderParts[0] as StatueId;

  files = files || folderParts.pop()!

  const filePaths = files
    .split(",")
    .map((v) => v.trim())
    .map((fileName) => {
      if (fileName.includes(".")) return fileName;
      return fileName + "." + ext;
    });

  const folder = serverPath + "/statues/" + folderParts.join("/") + "/";

  const res = {
    id,
    folder,
    ...(files
      ? {
          path: folder + filePaths[0],
          small: folder + filePaths[0],
          big: folder + (filePaths[1] || filePaths[0])
        }
      : {})
  };

  if (folderPath.includes('thangka')) {
    console.log({ res })
  }

  return res;
};
