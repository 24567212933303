import telegramLogo from "../../CommonComponents/Icons/telegram-logo";
import instagramLogo from "../../CommonComponents/Icons/instagram-logo";
import whatsappLogo from "../../CommonComponents/Icons/whatsapp-logo";
import { useState } from "react";

import { getUrlParams } from "../../../Services/htttp";
import { useStatue } from "../../../Services/Statues";
import { mapObject } from "../../../Utils/ArrayUtils";
import { getPicturePath } from "../../../Services/files";

const getLinks = (messageText: string) => [
  {
    link: "https://wa.me/79036848622?text=" + messageText,
    label: "via Whatsapp",
    logo: whatsappLogo,
    borderColor: "green",
    logoSize: 50,
    use: false
  },

  {
    link: "https://www.instagram.com/ozen_rajneesh",
    label: "via Instagram",
    logo: instagramLogo,
    borderColor: "violet",
    logoSize: 40,
    use: true
  },

  {
    link: "https://t.me/dimoff66",
    label: "via Telegram",
    logo: telegramLogo,
    borderColor: "blue",
    logoSize: 40,
    use: false
  }
];

export const useContactUsData = () => {
  const [paramsStr] = useState(window.location.search);
  const params = getUrlParams(paramsStr);

  const statueId = params["statue"];
  delete params["statue"];

  const statue = useStatue(statueId);
  if (statue) {
    Object.assign(statue.chosenOptions, params);
  }

  const statueDescription =
    statue &&
    `Hello! I'm interesting in statue ${statue.name} (` +
      mapObject(params, (k, v) => `${k}: ${v}`).join("; ") +
      ")";

  if (statueDescription) {
    navigator.clipboard.writeText(statueDescription);
  }

  const label = statue
    ? `DESCRIPTION OF ${statue.name.toUpperCase()} IS COPIED TO CLIPBOARD
      TO ASK US A PRICE JUST SEND US A MESSAGE 
      PASTING TEXT TO THE CHAT WINDOW
      OF CHOSEN MESSENGER
    `
    : `PLEASE CONTACT US IN CASE OF ANY QUESTIONS`;

  const imageLink = statue
    ? statue.getPaths(true)[0] ||
      statue.settings.images[0].path2 ||
      statue.settings.images[0].path
    : getPicturePath("pages/contact-us/phone-3.jpg");

  const imageClassName = statue ? "StatueImage" : "";

  const links = getLinks(statueDescription || "").filter(v => v.use);

  return {
    statue,
    label,
    imageLink,
    imageClassName,
    links
  };
};
