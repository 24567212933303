import "./styles.css";
import { getStyleClassName, joinClassNames } from "../utils";
import { mapObject } from "../../../Utils/ArrayUtils";
import Globals from "../../../Services/Globals";
import { useState } from "react";
import { MenuPanelProps } from "../../../Types/others";
import TwoLines from "../Buttons/TwoLines";
import SearchButton from "../Buttons/SearchButton";
import ButtonBack from "../Buttons/Back";

import Logo from "../Icons/Logo";
import Cart from "../Buttons/Cart";
import { capitalize } from "../../../Utils/StringUtils";

const MenuPanel: React.FC<MenuPanelProps> = (startProps) => {
  const [props, setMenuProps] = useState(startProps);
  Globals.setMenuHandler(setMenuProps);

  const { page } = props;

  const stylesClassName = getStyleClassName(page, "Page");
  const className = joinClassNames("MenuPanel", stylesClassName);

  const twoLinesProps =
    page === "menu" ? { href: "/", form: "cross" as "cross" } : {};

  const logoProps = page === "menu" ? {} : { href: "/" };

  const elements = {
    twoLines: {
      element: () => <TwoLines {...twoLinesProps} />
    },

    logo: {
      element: () => <Logo size={10} {...logoProps} />
    },

    search: {
      element: () => <SearchButton />
    },

    cart: {
      element: () => <Cart />
    },

    back: {
      element: () => <ButtonBack />
    },

    whiteLotusTitle: {
      element: () => (
        <div className="FlexV AlignCenter FontInterOnly">
          <div>WHITE LOTUS</div>
          <div>SCULPTURES</div>
        </div>
      )
    }
  };

  return (
    <div className={className}>
      {mapObject(elements, (key: string, v) => (
        <div className={"MenuPointWrapper Point" + capitalize(key)}>
          {v.element()}
        </div>
      ))}
    </div>
  );
};

export default MenuPanel;
