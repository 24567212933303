import { useCallback, useEffect, useState } from "react";
import { Dict } from "../Types";

export const useIncreaser = (v = 0, minValue?: number, maxValue?: number) => {
  const [value, changeValue] = useState(v);
  const increase = (v = 1) => {
    const newValue = value + v 
    if (minValue !== undefined && newValue < minValue) return false 
    if (maxValue !== undefined && newValue > maxValue) return false 

    changeValue(value + v);
    return true
  };

  return [value, increase, changeValue] as const;
};

export const useUpdater = () => {
  const [value, changeValue] = useState(true);
  const updater = () => changeValue(!value);

  return updater;
};

export const useConst = <T>(v: T) => {
  const [value] = useState(v);
  return value;
};

export const useConstMap = <T extends Dict>(v: T) => {
  const [value] = useState(v);
  Object.assign(value, v);
  return value;
};

export const useIsFirst = <TT = boolean, TF = boolean>(
  vTrue?: TT,
  vFalse?: TF
) => {
  const v = useConst({ value: 0 });

  useEffect(() => {
    v.value++;
  }, []);

  return v.value ? vFalse || false : vTrue || true;
};

export const useOnce = (fn: () => void, useBefore = true) => {
  const v = useIsFirst();
  const executeCallback = useCallback(() => {
    if (v) fn();
  }, [fn, v]);

  if (useBefore) executeCallback();

  useEffect(() => {
    if (useBefore || !v) return;
    executeCallback();
  }, [v, useBefore, executeCallback]);
};
