import { Link } from "react-router-dom";
import { toNbsp } from "../../../../Utils/StringUtils";
import { getStyleClassName, joinClassNames } from "../../utils";
import "./styles.css";

type Style = React.HTMLAttributes<HTMLDivElement>["style"];

export type ButtonProps = {
  label?: string;
  className?: string;
  style?: Style;
  onClick?: () => void;
  href?: string;
  variant?: string;
  children?: any
};

const BaseButton: React.FC<ButtonProps> = (props) => {
  const link =
    props.href &&
    ((className: string, el: React.ReactElement) =>
      props.href!.startsWith("http") || props.href!.startsWith("www.") ? (
        <a
          href={props.href}
          className={className}
          target="_blank"
          rel="noreferrer"
        >
          {el}
        </a>
      ) : (
        <Link to={props.href!} className={className}>
          {el}
        </Link>
      ));

  const mainClassName = "BaseButton Centered CursorPointer";
  const variantClassName = getStyleClassName(props.variant);

  let className = "";
  let linkClassName = "";

  const isOuterClass = (cl: string) => {
    if (cl.startsWith("Margin") || cl.startsWith("Top/")) return true;
  };

  joinClassNames(mainClassName, props.className, variantClassName)
    .split(" ")
    .forEach((cl) => {
      if (!link || !isOuterClass(cl)) {
        className += " " + cl.replace("Top/", "");
      } else {
        const linkClass = cl.replace("Top/", "");
        linkClassName += " " + linkClass;
      }
    });

  const content = props.children || toNbsp(props.label || "");

  const button = (
    <div className={className} style={props.style} onClick={props.onClick}>
      {content}
    </div>
  );

  return link ? link(linkClassName, button) : button;
};

export default BaseButton;
