import { getPicturePath } from "../../../Services/files"

export const menuPoints = [
  {
    label: "gallery",
    imgPath: getPicturePath('pages/menu/gallery-point.jpg'),
    link: "/gallery"
  },
  {
    label: "about us",
    imgPath: getPicturePath('pages/menu/about-us-point.jpg'),
    link: "/our-team"
  },
  {
    label: "contacts",
    imgPath: getPicturePath('pages/menu/contact-us-point.jpg'),
    link: "/contact-us"
  },
  {
    label: "tibetan wisdom",
    imgPath: getPicturePath('pages/menu/tibetan-wisdom-point.jpg'),
    link: "/tibetan-wisdom"
  }
];
