import { getPicturePath } from "../../../Services/files";

const ozenImageName = "ozen.png"
const folderPath = getPicturePath("pages/our team/", "");

const getPath = (fileName: string) => folderPath + fileName;

export const useData = () => {
  const videoPath = getPath("people1.mp4");

  const paths = {
    ozen: getPath(ozenImageName),

    people: [
      { poster: getPath("our-team-cover-chinmayo.jpg"), video: videoPath },
      { poster: getPath("our-team-cover-anna.jpg"), video: videoPath },
      { poster: getPath("our-team-cover-marco.jpg"), video: videoPath },
      { poster: getPath("our-team-cover-vez.jpg"), video: videoPath },
    ]
  };

  return { paths }
}