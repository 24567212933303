import { getPicturePath } from "../../../Services/files";
import MovingBottom from "../../CommonComponents/Buttons/MovingBottom";

export const useData = () => {
  const picturesFolder = "pages/tibetan-wisdom/";

  const pages = [
    {
      pageId: "OM_MANI_PADME_HUM",
      picture: "twisdom-1.jpg"
    },
    {
      pageId: "LOTUS_FLOWER",
      picture: "twisdom-2.jpg"
    },
    {
      pageId: "MANDALA",
      picture: "twisdom-3.jpg"
    }
  ].map((v) => ({
    ...v,
    title: v.pageId.split("_").join(" "),
    ref: "/" + v.pageId.split("_").join("-").toLowerCase(),
    picturePath: getPicturePath(`${picturesFolder}${v.picture}`, "")
  }));

  const button = (page: typeof pages[number]) => (
    <MovingBottom href={page.ref} label={"LEARN MORE"} />
  );

  return { pages, button }
}