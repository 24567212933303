import { statuesSettings } from "../../../Services/Statues/data";
import { getStatuePicturePaths } from "../../../Services/files";

export const getGalleryImages = () => {
  const images: Array<{
    path: string;
    pathMobile?: string;
    link: string;

    description: string;
    paddingTop?: number | string;
  }> = [
    {
      path: "avalokiteshvara-standing/9.jpeg",
      pathMobile: "avalokiteshvara-standing/1.jpeg",
      link: "avalokiteshvara-standing",

      description: "standing, 120 sm",
      paddingTop: 3
    },

    {
      path: "avalokiteshvara-seated/5.jpeg",
      link: "avalokiteshvara-seated",

      description: "seated, 100 sm",
      paddingTop: -5
    },

    {
      path: "manjushri/10.jpeg",
      link: "manjushri",

      description: "standing, 140 sm",
      paddingTop: 5
    },

    {
      path: "mahakala-blue/7.jpeg",
      link: "mahakala-blue",

      description: "Blue, 130 sm"
    },

    {
      path: "mahakala-white/210.jpg",
      link: "mahakala-white",

      description: "White, 100 sm",
      paddingTop: -2
    },

    {
      path: "megasambara-blue/206.jpg",
      link: "megasambara-blue",

      description: "Blue, 130 sm"
    },

    {
      path: "heruka/210.jpg",
      link: "heruka",

      description: "White, 190 sm",
      paddingTop: 5
    },

    {
      path: "saraswati/1.jpg",
      link: "saraswati",

      description: "White, 110 sm",
      paddingTop: 5
    },

    {
      path: "megasambara-gold/1.jpeg",
      link: "megasambara-gold",

      description: "Gold, 100 sm",
      paddingTop: 7
    }
  ];

  const images2 = images.map((image) => {
    const paths = getStatuePicturePaths(image.path);
    const pathsMobile = getStatuePicturePaths(image.pathMobile || image.path);
    const statueName = statuesSettings[paths.id].name;

    return {
      ...image,
      path: paths.path,
      pathMobile: pathsMobile.path,
      id: paths.id,
      name: statueName
    };
  });

  return images2;
};
