import { getPicturePath } from "../../../../Services/files";
import { pagesContent } from "../labels";
import SmallArticle from "../../../CommonComponents/SmallArticle";
import "./styles.css";
import { useChangePage } from "../../utils";
import WisdomPage from "../WisdomPage";
import { PageProps } from "../../../../Types/others";
import { isMobile } from "../../../../Utils";

const LotusFlower: React.FC<PageProps> = (props) => {
  const mobile = isMobile(props)
  useChangePage("LotusFlower");

  const imagesPath = getPicturePath("pages/tibetan-wisdom/", "");
  const secondImagePath = imagesPath + "lotus-flower-2.jpg"

  const secondImageBlock = 
    <div className="SecondImageBlock FlexH FullWidth PosRelative">
      <div
        className="ArticleBlock2 PosAbsolute"
        style={{ right: 190, top: -260, width: 300 }}
      >
        <SmallArticle
          article={pagesContent.lotusFlower[1]}
          articleStyle={{ gap: 20, fontWeight: 600, fontSize: 11 }}
        />
      </div>
      <div className="SecondImage">
        <img className="FullWidth" alt="..." src={secondImagePath} />
      </div>
    </div>

  const mobileSecondImageBlock = 
    <SmallArticle
      blockClassName="WisdomPageArticle"
      article={{
        elementAbove: <img
          className="WisdomPageImage FullWidth"
          alt="..."
          src={secondImagePath}
        />,

        text: pagesContent.lotusFlower[1]
      }}
    />  

  return (
    <WisdomPage
      mobile={Boolean(mobile)}
      title="Lotus flower"
      article={pagesContent.lotusFlower[0]}
      imageFileName="lotus-flower-1.jpg"
      className="LotusFlowerPage"
    >
      {!mobile ? secondImageBlock : mobileSecondImageBlock}  
    </WisdomPage>
  );
};

export default LotusFlower;
