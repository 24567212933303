import Logo from '../Icons/Logo'

type ComponentProps = {
  pageName: string
}

const UnderDevelopment: React.FC<ComponentProps> = props => {
  return <div className="FullSize FlexV Centered" style={{
    fontSize: 40
  }}>
    <Logo href="/" className="Padding20" />
    <div className="FlexV UnderdevelopmentLabel">
      <div className="Centered">{'Page "' + props.pageName + '"'}</div>
      <div className="MarginTop20">is under development</div>
    </div>
  </div>
}

export default UnderDevelopment