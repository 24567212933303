import { StatueDataExisting } from "../../../../Services/Statues";
import BaseButton from "../../../CommonComponents/Buttons/BaseButton";
import { joinClassNames } from "../../../CommonComponents/utils";

const ConnectUsButton: React.FC<{
  statue: StatueDataExisting;
  className?: string;
  label?: string;
}> = (props) => {
  const className = joinClassNames("ConnectUsButton", props.className);

  const label = props.label || "ASK FOR PRICE";

  return (
    <BaseButton
      className={className}
      variant="light rounded"
      href={props.statue.contactUsLink}
    >
      {label}
    </BaseButton>
  );
};

export default ConnectUsButton;
