import React, { useEffect, useState } from "react";
import Globals from "../../../Services/Globals";
import { createTouchEvents } from "../../../Services/TouchEvents";
import { StatueDataExisting, useStatue } from "../../../Services/Statues";
import { getProperty } from "../../../Services/Statues/properties";

import { chunk2 } from "../../../Utils/ArrayUtils";
import BaseButton from "../../CommonComponents/Buttons/BaseButton";
import { joinClassNames } from "../../CommonComponents/utils";
import ConnectUsButton from "./Buttons/ConnectUsButton";
import ThreeDModelButton from "./Buttons/ThreeDModelButton";

type StatueItemMobleProps = {
  statue: NonNullable<ReturnType<typeof useStatue>>;
};

const StatueItemMobile: React.FC<StatueItemMobleProps> = (props) => {
  const { statue } = props;

  const [imageWidth, changeImageWidth] = useState(0);
  useEffect(() => {
    if (!imageWidth) {
      const elem = document.querySelector(".ImageWrapper");
      if (!elem) return;

      changeImageWidth(elem.clientWidth);
    }
  }, [imageWidth]);

  const [imageShift, changeImageShiftOrig] = useState(0);
  const changeImageShift = (shift: 1 | -1) => {
    const newShift = imageShift + shift;
    if (newShift > 0 || -newShift === statue.imagesPaths.length) {
      return;
    }

    changeImageShiftOrig(newShift);
  };

  const [optionsOpen, changeOptionsStateOrig] = useState(false);
  const changeOptionsState = () => changeOptionsStateOrig(!optionsOpen);

  useEffect(() => {
    if (imageShift !== 0) {
      changeImageShiftOrig(0)
    }

    if (optionsOpen) {
      changeOptionsState()
    }
  }, [statue.propsPath])

  const optionsBlockClassName = joinClassNames(
    "OptionsBlock PosAbsolute FullWidth",
    optionsOpen ? "isOpen" : "isClosed"
  );

  const touchEventsService = createTouchEvents({
    onEnd(m) {
      if (m.left) {
        changeImageShift(-1);
      } else if (m.right) {
        changeImageShift(1);
      }
    }
  });

  const touchEventsServiceDescr = createTouchEvents({
    onEnd(m) {
      if (m.up && !optionsOpen) {
        changeOptionsState();
      } else if (m.down && optionsOpen) {
        changeOptionsState();
      }
    }
  });

  const ImagePoint = (props: { isCurrent: boolean }) => {
    return (
      <div
        className={joinClassNames("ImagePoint", props.isCurrent && "Current")}
      />
    );
  };

  const blockHeight = window.innerHeight - 80;

  return (
    <div
      className="StatueItemMobileBlock PosRelative FontInterOnly UseTest"
      style={{ height: blockHeight }}
    >
      <div
        className="StatueImagesBlock FullWidth PosRelative ScrollV"
        {...touchEventsService.events}
      >
        <div
          className="ImagesPoints FlexH AbsoluteCopy Centered"
          style={{ zIndex: 900 }}
        >
          <div className="FlexH" style={{ gap: 5 }}>
            {statue.imagesPaths.map((_, ind) => (
              <ImagePoint isCurrent={ind === -imageShift} />
            ))}
          </div>
        </div>
        <div
          className="StatueImagesRow FlexH AbsoluteCopy"
          style={{ left: imageShift * imageWidth }}
        >
          {statue.imagesPaths.map((path) => (
            <div
              className="ImageWrapper FullWidth Centered"
              onClick={() => changeImageShift(-1)}
            >
              <div className="HeightControlWrapper">
                <img className="FullWidth" src={path.path} alt="loading" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={optionsBlockClassName}
        {...touchEventsServiceDescr.events}
      >
        <BaseButton
          className="MobileOptionsExpanderButton"
          variant="noBorder"
          onClick={changeOptionsState}
        >
          <div className="OptionsExpander" />
        </BaseButton>
        <div className="StatueName">
          <div className="BlockTitle">{statue.name}</div>
          <div className="BlockText">{statue.chosenOptionsString}</div>
        </div>

        <div className="StatueDescription OnlyOnOpen">
          <div className="BlockTitle">ABOUT</div>
          <div className="BlockText">{statue.settings.description}</div>
        </div>

        <Options statue={statue} />

        <div className="Buttons Centered FlexH MarginTop20 OnlyOnOpen">
          <ConnectUsButton statue={statue} />
          <div className="Grow" />
          <ThreeDModelButton statue={statue} />
        </div>
      </div>
    </div>
  );
};

export default StatueItemMobile;

const Options: React.FC<{ statue: StatueDataExisting }> = (props) => {
  const { statue } = props;
  const optionsRows = chunk2(statue.options);

  const onOptionClick = (
    option: typeof statue.options[number],
    currentValue: string | null
  ) => () => {
    Globals.chooseListValue({
      values: option.values,
      currentValue,
      callback: (v) => {
        if (!v) return;
        statue.changeOption(option.property, v);
      },
      label: "Choose " + option.property
    });
  };

  return (
    <div className="StatueOptions OnlyOnOpen">
      {optionsRows.map((row) => (
        <div className="StatueOptionsRow FlexH">
          {row.filter(Boolean).map((option) => {
            const chosenValue = statue.getChosenValue(option!);

            return (
              <div className="StatueOptionBlock FlexH">
                <div
                  className="StatueOptionIcon"
                  dangerouslySetInnerHTML={{
                    __html: getProperty(option!.property).icon
                  }}
                />
                <div className="StatueOptionInfo FlexV">
                  <div className="StatueOptionName TextUpper">
                    {option!.property}
                  </div>
                  <div
                    className="StatueOptionValue CursorPointer"
                    onClick={onOptionClick(option!, chosenValue.value)}
                  >
                    {chosenValue.view}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
