import React from "react";
import Globals from "../../../Services/Globals";
import { useStatue } from "../../../Services/Statues";
import BaseButton from "../../CommonComponents/Buttons/BaseButton/BaseButton";
import { useChangePage } from "../utils";
import "./styles.css";

const Thangka: React.FC = () => {
  useChangePage("thangka", false);
  const navigateBack = Globals.useNavigateBack()

  const statue = useStatue();

  if (!statue) return null;
  if (!statue.thangka) return null;

  const { image, description } = statue.thangka;
  if (!image || !description) return null;

  return (
    <div className="ThangkaBlock FlexH FullWidth">
      <div className="EmptyBlock FullHeight">
        <div className="ButtonBackWrapper FlexH">
          <div className="Grow" />
          <BaseButton
            label="⮝"
            className="ButtonBack"
            variant="circle"
            style={{ height: 50, width: 50 }}
            onClick={navigateBack}
          />
        </div>
      </div>
      <div className="ThangkaDescr FullHeight FlexV">
        <div className="Grow" />
        <div className="StatueName FlexH FullWidth FontInterOnly">
          {statue.name.split("").map((line) => (
            <div>{line}</div>
          ))}
        </div>
        <div className="StatueNameUnderlined" />
        <div className="DescriptionText FontInterOnly FlexV">
          {description.split("%").map((part) => (
            <div>{part}</div>
          ))}
        </div>
      </div>
      <div className="ThangkaImageBlock Grow Centered FullHeight">
        <img alt="..." src={image.path} className="ThangkaImage FullHeight" />
      </div>
    </div>
  );
};

export default Thangka;
