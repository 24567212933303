import BaseButton from "../../CommonComponents/Buttons/BaseButton";
import { joinClassNames } from "../../CommonComponents/utils";
import { menuPoints } from "./data";

const MobileMenu: React.FC<{ className: string }> = (props) => {
  const points = menuPoints;
  const className = joinClassNames("MobileMenuBlock", props.className);

  console.log({ className });

  return (
    <div className={className}>
      {points.map((p) => (
        <BaseButton
          className="MenuPoint TextUpper"
          label={p.label}
          variant="noBorder"
          href={p.link}
        />
      ))}
    </div>
  );
};

export default MobileMenu;
