const eventsChangeHolder = {
  onKeyPress: (keyCode: string) => {},
  onKeyDown: (keyCode: string) => {}
};

export const onKeyPress = (keyCode: string) => {
  eventsChangeHolder.onKeyPress(keyCode);
};
export const setOnKeyPressHandler = (
  handler: typeof eventsChangeHolder["onKeyPress"]
) => {
  eventsChangeHolder.onKeyPress = handler;
};

export const onKeyDown = (keyCode: string) => {
  eventsChangeHolder.onKeyDown(keyCode);
};
export const setOnKeyDownHandler = (
  handler: typeof eventsChangeHolder["onKeyDown"]
) => {
  eventsChangeHolder.onKeyDown = handler;
};
