import React, { useState } from 'react'
import Globals from '../../../Services/Globals'
import './styles.css'

const Modal: React.FC<{}> = props => {
  const [content, changeContent] = useState<React.ReactElement | null>(null)
  Globals.setModalCallback(changeContent)

  if (!content) return null 

  return <div 
    className="Modal AbsoluteCopy Centered"
    onClick={() => changeContent(null)}
  >
    {content}
  </div>
}

export default Modal