import "./styles.css";
import Button, { ButtonProps } from "../../../CommonComponents/Buttons/BaseButton/BaseButton";
import { joinClassNames } from "../../utils";

const MovingBottomButton = (props: ButtonProps) => {
  const buttonProps = {
    ...props,
    className: joinClassNames('MovingBottom', props.className)
  }

  return (
    <Button {...buttonProps} />
  );
};

export default MovingBottomButton;
