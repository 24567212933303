import { getBottomPoints } from "./utils";
import Logo from '../Icons/Logo'
import BaseButton from '../Buttons/BaseButton'
import { toNbsp } from "../../../Utils/StringUtils";

import './styles.css'

const Footer: React.FC<{ isMobile?: boolean }> = props => {
  if (props.isMobile) return <MobileFooter />

  const footerPoints = getBottomPoints();
  return (
    <div className="FullWidth FlexH DesctopFooter">
      <Logo className="Padding20" size={7} opacity={0.6} />
      {footerPoints.map((point) => {
        return (
          <div
            className={
              "FlexV FooterPart " + point.block.replace(" ", "_")
            }
            style={point.style || {}}
          >
            <div className="FooterTitle TextUpper">{toNbsp(point.block)}</div>
            <div className="FlexV LinksBlock">
              {point.points.map(({ label, link }) => {
                return link ? (
                  <BaseButton
                    className="FooterLink"
                    variant="noBorder"
                    href={link}
                    label={label}
                  />
                ) : (
                  <div className="FooterLabel">{label}</div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Footer 

const MobileFooter: React.FC<{}> = props => {
  const footerPoints = getBottomPoints();
  return (
    <div className="FullWidth FlexV MobileFooter PaddingTop20">
      {footerPoints.map((point, ind) => {
        return (
          <div
            className={
              "FlexV FullWidth FooterPart " + point.block.replace(" ", "_")
            }
          >
            <div className="FlexH">
              <div className={ind % 2 === 1 ? 'Grow' : ''} />
              <div className="FooterTitle TextUpper">{point.block}</div>
            </div>
            {point.points.map(({ label, link }) => {
              return <div className="FlexH">
                <div className={ind % 2 === 1 ? 'Grow' : ''} />

                {link ? (
                <BaseButton
                  className="FooterLink"
                  variant="noBorder"
                  href={link}
                  label={label}
                />
              ) : (
                <div className="FooterLabel">{label}</div>
              )}
            </div>
            })}
          </div>
        );
      })}
      <div className="LastLabel FlexV AlignCenter FooterLabel FullWidth">
        <div>"WHITE LOTUS SCULPTURES" LTD</div> 
        <div> 2022 </div>
      </div>
    </div>
  );

}