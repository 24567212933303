import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../CommonComponents/Footer";
import Eye from "../../CommonComponents/Icons/Eye";
import { getGalleryImages } from "./utils";

export const GalleryMobile: React.FC<{}> = (props) => {
  const images = getGalleryImages();
  return (
    <div className="MobileGalleryBlock FlexV FullWidth">
      {images.map((image) => (
        <Link
          className="MobileGalleryImageWrapper FullWidth PosRelative"
          to={`/statues/${image.link}`}
        >
          <img
            className="MobileGalleryImage FullWidth"
            alt="..."
            src={image.pathMobile}
          />

          <div className="AbsoluteCopy Centered">
            <Eye className="EyeStatue" />
          </div>
        </Link>
      ))}

      <Footer isMobile />
    </div>
  );
};

export default GalleryMobile;
