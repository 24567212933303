import { statuesSettings } from "../../../Services/Statues/data";
import { getStatuePicturePaths } from "../../../Services/files";

type LandingImageProps = {
  path: string;
  link: string;

  labelPosition?: "left" | "right";
  positionV?: "top" | "bottom" | "center";
  positionH?: "left" | "right" | "center";
  marginV?: any;
  marginH?: any;

  imageName?: string;
  imageWidth?: string | number;
  descriptionWidth?: number;
  fontSize?: number;
  letterSpacing?: number;
  opacity?: number;
  content?: any;

  logoOpacity?: number;

  buttonLabel?: string;
  buttonPosition?: "under" | "above";
  buttonMargin?: number;
  buttonGap?: number;
};

export const getLandingImages = () => {
  const images: LandingImageProps[] = [
    {
      path: "mahakala-blue/landing.jpg",
      link: "mahakala-blue",

      // props
      labelPosition: "left",
      positionV: "bottom",
      marginV: 100,
      marginH: -180
    },

    {
      path: "avalokiteshvara-standing/landing.jpg",
      link: "avalokiteshvara-standing",

      // props
      descriptionWidth: 60,
      positionV: "bottom",
      marginV: "20%",
      positionH: "right",
      marginH: 150
    },

    {
      path: "saraswati/landing-7.jpg",
      link: "saraswati",

      //props
      labelPosition: "left",
      positionV: "bottom",
      marginV: "10%"
    },

    {
      path: "heruka/landing.jpg",
      link: "heruka",

      //props
      labelPosition: "right",
      positionV: "bottom",
      marginV: "10%"
    },

    {
      path: "megasambara-gold/landing.jpg",
      link: "megasambara-gold",

      //props
      labelPosition: "left",
      positionV: "bottom",
      marginV: 40
    },

    {
      path: "manjushri/landing.jpg",
      link: "manjushri",

      //props
      positionV: "bottom",
      marginV: "20%",
      marginH: -550
    },

    {
      path: "buddha/landing.jpg",
      link: "buddha",

      //props
      labelPosition: "left",
      positionV: "bottom",
      imageWidth: "100%",
      marginV: 40,
      marginH: -80,
      logoOpacity: 0.6
    }
  ];

  const images2 = images.map((image) => {
    const paths = getStatuePicturePaths(image.path);
    const statueName = statuesSettings[paths.id].name;

    return {
      ...image,
      path: paths.path,
      id: paths.id,
      name: statueName
    };
  });

  return images2;
};

export const labels = {
  ourTeamDescription: `We are international team of people who is living and meditating 
  together... And we are doing this sculpture work with our hearts
  full of love and gratitude to existence... sharing our vision of
  beauty and estetic inside the tibetian and indian religious
  traditions.`
};
