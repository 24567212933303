const createPoints = (text: string) => {
  return text
    .split("\n")
    .filter((v) => Boolean(v.trim()))
    .map((line) => {
      const [label, link] = line.split("|").map((v) => v.trim());
      return { label, link };
    });
};

//////////////////////////////////////////////////
//
export const getBottomPoints = () => {
  const points = [
    {
      block: "Tibetan wisdom",
      points: [
        { label: "Lotus flower", link: "/lotus-flower" },
        { label: "Om mani padme hum", link: "/om-mani-padme-hum" },
        { label: "Mandala", link: "/mandala" }
      ],

      style: { marginLeft: 150 }
    },

    {
      block: "About us",
      points: [
        { label: "Our team", link: "/our-team" },
        { label: "Process of creation", link: "" }
      ]
    },

    {
      block: "Contact us",
      points: createPoints(`
        our instagram | https://www.instagram.com/ozen_rajneesh/
        our facebook | https://www.facebook.com/ozenswamirajneesh 
      `)
    }
  ];

  return points;
};