import { pagesContent } from "../labels";
import "./styles.css";
import { useChangePage } from "../../utils";
import WisdomPage from "../WisdomPage";
import { PageProps } from "../../../../Types/others";
import { isMobile } from "../../../../Utils";


const OmMani: React.FC<PageProps> = (props) => {
  const mobile = isMobile(props)
  useChangePage("OmMani");

  return (
    <WisdomPage
      title="Om mani padme hum"
      article={pagesContent.omMani}
      imageFileName="om_mani.jpg"
      className="OmManiPage"
      mobile={Boolean(mobile)}
    ></WisdomPage>
  );
};

export default OmMani;
