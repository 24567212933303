import { capitalize } from "../../Utils/StringUtils";

import propColorIcon from "../../Components/CommonComponents/Icons/property-color-icon";

const view = (v: string) => capitalize(v.replace("_", " "));

export const statuesProperties = {
  position: {
    icon: propColorIcon,
    values: ["standing", "seated"] as const
  },

  color: {
    icon: propColorIcon,
    values: ["blue", "red", "white", "gold"] as const
  },

  // size120, size150, size180 etc
  size: {
    icon: propColorIcon,
    values: ["size120", "size150", "size180", "size200"] as const,
    view(size: string) {
      return size.slice(4) + " cm";
    }
  },

  jewellery: {
    icon: propColorIcon,
    values: ["diamonds", "plastic", "no_jewellery"] as const
  },

  material: {
    icon: propColorIcon,
    values: ["resin", "bronze"] as const,
    defaultValue: 'resin',
    view (material: string) {
      if (material === 'resin') return 'Resin marble powder'
      return view(material)
    }
  }
};

export const getProperty = (prop: StatueProp) => {
  const propDefinition = statuesProperties[prop]
  return {
    ...propDefinition,
    view: 'view' in propDefinition ? propDefinition.view : view,
    defaultValue: 'defaultValue' in propDefinition ? propDefinition.defaultValue : undefined
  }
};

export const getValues = (prop: StatueProp) =>
  [...getProperty(prop).values] as string[];

export const propHasValue = (prop: StatueProp, value: string) => {
  return getValues(prop).includes(value);
};

export const getView = (prop: StatueProp | string, value: string) => {
  return getProperty(prop as StatueProp).view(value);
};

export type StatueProp = keyof typeof statuesProperties;
