import SplittedText from "../../CommonComponents/SplittedText";
import VideoPlayer from "../../CommonComponents/VideoPlayer";
import { getPicturePath } from "../../../Services/files";
import { ozenDescription } from "./data";
import "./styles.css";
import { useChangePage } from "../utils";
import { PageProps } from "../../../Types/others";
import { useData } from "./utils";
import { chunk2 } from "../../../Utils/ArrayUtils";
import { isMobile } from "../../../Utils";
import OurTeamMobile from "./OurTeamMobile";

const OurTeam: React.FC<PageProps> = props => {
  const mobile = isMobile(props)
  useChangePage("ourTeam");

  const data = useData()
  
  if (mobile) {
    return <OurTeamMobile data={data} />
  }

  const { paths } = data
  const peopleFiles = chunk2(paths.people)

  return (
    <div className="OurTeamBlock">
      <div className="OurTeamMainTitle">OUR TEAM</div>
      <div className="OzenBlock FlexH">
        <div className="OzenImageWrapper">
          <img alt="..." src={paths.ozen} className="FullWidth" />
        </div>
        
        <div className="OzenDescriptionBlock JustifyCenter">
          <div className="FlexV">
            <div className="OzenName">OZEN RAJNEESH</div>
            <div className="OzenDescription">
              <SplittedText text={ozenDescription} />
            </div>
          </div>
        </div>
      </div>
      <div className="Videos FlexV">
        {
          peopleFiles.map(row => (
            <div className="VideoRow FlexH">
              {row.map(v => (
                <div className="VideoWrapper">
                  <VideoPlayer url={v!.video} poster={v!.poster} />
                </div>
              ))}  
            </div>  
          ))
        }
      </div>
    </div>
  );
};

export default OurTeam;
