import { StatueDataExisting } from "../../../../Services/Statues";
import Button from "../../../CommonComponents/Buttons/BaseButton";
import { joinClassNames } from "../../../CommonComponents/utils";

export default (props: { statue: StatueDataExisting; className?: string }) => {
  const className = joinClassNames("Watch3D", props.className);

  return (
    <Button
      className={className}
      variant="rounded"
      href={props.statue._3dLink}
    >
      3D MODEL
    </Button>
  );
};
