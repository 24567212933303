import { useStatue } from "../../../Services/Statues";

export const useData = () => {
  const statue = useStatue()

  const apologizeMessage = `
    We apologize !!! 
    3d model for this sculpture was not uploaded yet
    We are working on it
  `;

  return { statue, apologizeMessage }
}