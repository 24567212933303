import { useContactUsData } from "./data";

import BaseIcon from "../../CommonComponents/Icons/BaseLogo";
import BaseButton from "../../CommonComponents/Buttons/BaseButton";

type ContactUsMobileProps = {
  data: ReturnType<typeof useContactUsData>;
};

const ContactUsMobile: React.FC<ContactUsMobileProps> = (props) => {
  const { data } = props;
  data.label = data.label.replace("IN CASE", "\nIN CASE");

  const blockHeight = window.innerHeight - 128;

  return (
    <div
      className="ContactUsMobileBlock FlexV AlignCenter FontInterOnly FullWidth"
      style={{ height: blockHeight }}
    >
      <div className="MessageLinesBlock">
        {data.label.split("\n").map((label) => (
          <div className="Centered FullWidth">{label}</div>
        ))}
      </div>

      <div className="ContactLinks FlexV PosRelative MarginTop20 Grow FullWidth">
        <img
          className="PosAbsolute"
          alt="..."
          src={data.imageLink}
          style={{
            left: 0,
            top: 0,
            height: "calc(100vh - 128px - 50px)",
            opacity: 0.7
          }}
        />
        <div className="Grow" />
        <div
          className="LinksBlock FlexV AlignCenter FullWidth"
          style={{ gap: 20 }}
        >
          {data.links.map((link) => (
            <BaseButton
              className="Top/ContactLinkButton"
              href={link.link}
              variant="rounded light"
              style={{ border: `2px solid ${link.borderColor}` }}
            >
              <div className="FlexH FullWidth AlignCenter">
                <BaseIcon logo={link.logo} size={link.logoSize} />
                <div
                  className="Grow Centered"
                  style={{ fontSize: 20, fontWeight: 600 }}
                >
                  {link.label}
                </div>
              </div>
            </BaseButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUsMobile;
