export const avalokiteshvaraDescr = `Boddhisattva Avalokiteshvara of infinite compassion is also known as the one "who looked down upon sound", perceived the cries of those who need help.%
Names also used in China - Guanyin and Kuan-yin, in Japan - Kannon, in Tibet - Chenrezig.

From Sanskrit "avalokita" means "looking on" and "ishvara" means "lord".%
One of many forms of Avalokiteshvara is the thousand hands Buddha with eleven faces, also known as Mahakarunika (maha - great, karuna - compassion) - the Great Compassionate One.% 
According to the legend, trying to reach out all those who needed help, Avalokiteshvara saw the amount of suffering beings and his head and two arms shattered into pieces. Amitabha came to help him and collected all the pieces into 11 heads and a thousand arms. Such a transformation has increased the power of the Great Compassionate, helped to interact not only with those who want to grow, but also with those who are still ignorant.%
The name of Boddhisattva Avalokiteshvara is related with mantra om mani padme hum which is translated as “the jewel is in the lotus”.`;

export const mahakalaDescr = `
Mahakala is the God of Time, Maya, Creation, Destruction and Power. He is
affiliated with Lord Shiva. His abode is the cremation grounds and has four arms and three
eyes, sitting on five corpse.% He holds trident, drum, sword and hammer. He rubs ashes from
the cremation ground. He is surrounded by vultures and jackals. His consort is Kali. Both
together personify time and destructive powers.% The paper deals with Sacred Mahakala
and it mentions legends, tales, myths in Hindus and Buddhist texts. It includes various
types, forms and iconographic features of Mahakalas. This research concludes that sacred
Mahakala is of great significance to both the Buddhist and the Hindus alike.`;
