import { getPicturePath } from "../../../../Services/files";
import { ElementStyle } from "../../../../Types";
import { toNbsp } from "../../../../Utils/StringUtils";
import SmallArticle from "../../../CommonComponents/SmallArticle";
import { joinClassNames } from "../../../CommonComponents/utils";
import "./styles.css";

type WisdomPageProps = {
  mobile: boolean,
  
  title: string;
  titleStyle?: ElementStyle;

  article: string;
  articleStyle?: ElementStyle;

  imageFileName: string;
  className?: string;

  children?: any
};

const WisdomPage: React.FC<WisdomPageProps> = (props) => {
  const { mobile, title, article } = props
  console.log({ mobile })
  const imagesPath = getPicturePath("pages/tibetan-wisdom/", "");

  const className = joinClassNames(
    props.className,
    "WisdomPage" + (mobile ? "MobileBlock" : "Block"),
    "FullWidth FontInterOnly"
  );

  const titleStyle = Object.assign(
    {
      fontSize: 22,
      fontWeight: 900,
      letterSpacing: 8
    },
    props.titleStyle || {}
  );

  const articleStyle = Object.assign(
    {
      gap: 10,
      fontWeight: 600,
      fontSize: 12
    },
    props.articleStyle || {}
  );

  if (mobile) {
    return <div className={className}>
      <SmallArticle
        blockClassName="WisdomPageArticle"
        title={toNbsp(title)}
        article={{
          elementAbove: <img
            className="WisdomPageImage FullWidth"
            alt="..."
            src={imagesPath + props.imageFileName}
          />,

          text: article
        }}
        titleStyle={titleStyle}
        articleStyle={articleStyle}
      />  
      {props.children}
    </div>
  }

  return (
    <div className={className}>
      <div className="FirstImageBlock FlexH FullWidth PosRelative">
        <div className="ArticleBlock PosAbsolute">
          <SmallArticle
            title={toNbsp(title)}
            article={article}
            titleStyle={titleStyle}
            articleStyle={articleStyle}
          />
        </div>
        <div className="Grow" />
        <div className="FirstImage">
          <img
            className="FullWidth"
            alt="..."
            src={imagesPath + props.imageFileName}
          />
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default WisdomPage;
