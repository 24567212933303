import ReactSimplePlayer from "./ReactPlayer";

type VideoPlayerProps = {
  url: string;
  poster?: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  return <ReactSimplePlayer url={props.url} poster={props.poster} autosize />;
};

export default VideoPlayer;
