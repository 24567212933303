import React, { Suspense, useEffect } from "react";

import utils from './Utils'

import { Canvas, useThree, useLoader, extend } from "@react-three/fiber";
import { OrbitControls, TransformControls } from 'three-stdlib'

extend({ OrbitControls, TransformControls })

let cameraGl: any

const CameraController = () => {
  const { camera, gl } = useThree();
  cameraGl = camera 

  useEffect(
    () => {
      const controls = new OrbitControls(camera, gl.domElement);

      controls.minDistance = 1;
      controls.maxDistance = 20;

      camera.position.set(0, 0, 3)
      controls.update();

      return () => {
        controls.dispose();
      };
    },
    [camera, gl]
  );
  return null;
};

type GLTFModelProps = {
  path?: string
}
const GltfModel: React.FC<GLTFModelProps> = props => {
  let path = props.path || 'sample.glb'
  // path = 'd51.gltf'  

  const res = useLoader(utils.GLTFLoader, path)

  // useEffect(() => {
  //   cameraGl.position.y += 1
  // }, [])

  return (
    <div className="_3DWrapper" style={{ 
      height: 'calc(100vh - 50px)',
    }}>
      {/* <div className="FlexH" style={{ display: 'none' }}>
        <div onClick={() => cameraGl.position.x += 1}>X</div>
        <div onClick={() => {
          cameraGl.position.y += 1
        }}>Y</div>
      </div> */}
      <Canvas>
        <ambientLight />
        <CameraController />
        <Suspense fallback={null}>
          <primitive 
            object={res.scene} 
          />
        </Suspense>
      </Canvas>
    </div>
  )
  
}

export default GltfModel

