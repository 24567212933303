import React, { useEffect, useState } from "react";
import Globals from "../../../Services/Globals";
import { useConst, useOnce } from "../../../Utils/HooksUtils";
import { joinClassNames, useCustomScroll } from "../utils";
import "./styles.css";

type AnimatedPicturesProp<T> = {
  key?: any;

  items: T[];
  content: (v: T, rowNumber: number, cellNumber: number) => React.ReactElement;
  rowHeight: number | string;
  itemInRow?: number;

  maxScrollsInQueue?: number

  listClass?: string;
  rowClass?: string;
  cellClass?: string;
};

const AnimatedPictures = <T, >(props: AnimatedPicturesProp<T>) => {
  const itemsInRow = props.itemInRow || 3;

  const [blockState, changeBlockState] = useState(0);

  const [rowHeight, changeRowHeight] = useState(props.rowHeight);
  useEffect(() => {
    if (props.rowHeight !== rowHeight) changeRowHeight(props.rowHeight);
  }, [props.rowHeight, rowHeight]);

  const [hiddenCount, changeHiddenCount] = useState(0);

  const listClassName = joinClassNames(
    props.listClass,
    "ImagesAnimation FlexV State_" + blockState,
    "ItemsInRow" + itemsInRow
  );

  const listRowClassName = joinClassNames(props.rowClass, "AnimationRow FlexH");

  const listCellClassName = joinClassNames(
    props.cellClass,
    "AnimationCell PosRelative"
  );

  const rows = props.items.reduce((agg, v, ind, arr) => {
    if (ind % itemsInRow === 0) {
      agg.push(Array.from({ length: itemsInRow }, (_, i) => arr[ind + i]));
    }
    return agg;
  }, [] as T[][]);

  const { onWheelMove, stateChanges } = useCustomScroll(undefined, props.maxScrollsInQueue)

  useEffect(() => {
    if (blockState) return;

    while (true) {
      const scroll = stateChanges.shift();
      if (!scroll) break;

      const scrollParams = {
        check:
          scroll === "up"
            ? () => hiddenCount === rows.length - 1
            : () => !hiddenCount,
        states: scroll === "up" ? [1, 2, 1] : [3, 4, -1]
      };

      if (scrollParams.check()) continue;

      changeBlockState(scrollParams.states[0]);
      setTimeout(() => {
        changeHiddenCount(hiddenCount + scrollParams.states[2]);
        changeBlockState(0);
      }, 1480);

      break;
    }
  }, [stateChanges, blockState, changeHiddenCount, hiddenCount, rows.length]);

  const getCellClientHeight = () => {
    const randomCell = document.querySelector(".AnimationCell");
    if (!randomCell) return 0;

    return randomCell.clientHeight || 0;
  };

  const realRowHeight =
    typeof rowHeight === "number" ? rowHeight : getCellClientHeight();

  const marginTop = -realRowHeight * hiddenCount;

  return (
    <div className={listClassName} onWheel={onWheelMove} key={props.key}>
      <div className="ImagesAnimationWrapper" style={{ marginTop }}>
        {rows.map((row, rowInd) => (
          <div className={listRowClassName}>
            {row.map(
              (v, cellInd) =>
                v && (
                  <div
                    className={listCellClassName}
                    style={{ height: props.rowHeight }}
                  >
                    {props.content(v, rowInd, cellInd)}
                  </div>
                )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedPictures;
