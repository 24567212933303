import { ElementStyle } from "../../../Types";
import { getPicturePath } from "../../../Services/files";
import { getLandingImages } from "./utils";
import BaseButton from "../../CommonComponents/Buttons/BaseButton";
import Footer from "../../CommonComponents/Footer";

const LandingMobile: React.FC<{}> = (props) => {
  const images = getLandingImages(); //
  const picturePositions = ["right", "left"] as const;

  const statuesElements = images.map((image, ind) => (
    <MobileStatueBlock
      picturePath={image.path!}
      picturePosition={picturePositions[ind % 2]}
      link={'/statues/' + image.link}
    />
  ));
  return (
    <div className="LandingMobileBlock FullWidth">
      <MobileStatueBlock
        picturePath={getPicturePath("pages/landing/main.jpg")}
        picturePosition="left"
        buttonLabel="view gallery"
        link="/gallery"
      />
      {statuesElements}
      <Footer isMobile />
    </div>
  );
};

////////////////////////////////////////////////////////
//
const MobileStatueBlock = (incomingProps: {
  picturePath: string;
  picturePosition: "left" | "right";
  buttonLabel?: string;
  link: string
}) => {
  const props = {
    buttonLabel: "learn more",
    ...incomingProps
  };

  const imageStyle: ElementStyle = {
    position: "absolute",
    height: "100%",
    left: props.picturePosition === "left" ? 0 : undefined,
    right: props.picturePosition === "right" ? 0 : undefined
  };

  const buttonStyle: ElementStyle = {
    position: "absolute",
    left: props.picturePosition === "right" ? 0 : undefined,
    right: props.picturePosition === "left" ? 0 : undefined
  };

  return (
    <div className="StatueMobile PosRelative">
      <img
        className="StatueImage"
        alt="..."
        src={props.picturePath}
        style={imageStyle}
      />

      <div className="StatueButtonBlock Centered" style={buttonStyle}>
        <BaseButton
          href={props.link}
          label={props.buttonLabel.toUpperCase()}
          variant="noBorder"
        />
      </div>
    </div>
  );
};

////////////////////////////////////////////////////
//

//////////////////////////////////////////////////////////////////
//

export default LandingMobile;
