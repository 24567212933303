const appExtraClasses = {
  value: (classes: string) => {}
};

export const setAppExtraClassesCallback = (
  v: typeof appExtraClasses["value"]
) => {
  appExtraClasses.value = v;
};

export const changeAppExtraClasses = (
  v: Parameters<typeof appExtraClasses["value"]>[0]
) => {
  appExtraClasses.value(v);
};
