import { useState } from "react";
import AnimatedPictures from "../../CommonComponents/AnimatedPictures";
import Logo from "../../CommonComponents/Icons/Logo";
import GltfModel from "../../CommonComponents/GltfModel";
import "./styles.css";

const Test = () => {
  return (
    <GltfModel />
  );
};

const Test3 = () => {
  const [blockState, changeBlockState] = useState(0);
  const changeStateValue = () => changeBlockState((blockState + 1) % 5);

  const [hiddenCount, changeHiddenCount] = useState(0);
  const changeHidden = (move: number) => changeHiddenCount(hiddenCount + move);

  const className = "ImagesAnimation FlexV State" + blockState;

  const rows = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9]
  ];

  const moveUp = () => {
    if (hiddenCount === rows.length) return;

    changeBlockState(1);
    setTimeout(() => {
      changeBlockState(2);
    }, 1000);
    setTimeout(() => {
      changeHidden(1);
      changeBlockState(0);
    }, 2000);
  };

  const moveDown = () => {
    if (!hiddenCount) return;

    changeBlockState(3);
    setTimeout(() => {
      changeBlockState(4);
    }, 1000);
    setTimeout(() => {
      changeHidden(-1);
      changeBlockState(0);
    }, 2000);
  };

  return (
    <div
      className={className}
      style={{
        overflowY: "hidden",
        height: 500
      }}
    >
      <div
        className="Wrapper"
        style={{
          marginTop: -80 * hiddenCount
        }}
      >
        {rows.map((row, ind) => (
          <div className="Row FlexH">
            {row.map((v) => (
              <div
                className="Cell Grow PosRelative"
                style={{
                  height: 80
                }}
              >
                <div className="ValueBlock PosAbsolute Centered FullSize">
                  {v}
                </div>
              </div>
            ))}
          </div>
        ))}
        <button className="MarginTop20" onClick={moveUp}>
          Up
        </button>
        <button className="MarginTop20" onClick={moveDown}>
          Down
        </button>
      </div>
    </div>
  );
};

const Test1 = () => {
  const [step, changeStep] = useState(1);
  return (
    <div className="FullSize UseTest">
      <div className="Container FullWidth Green PosRelative">
        <div className={"Item PosAbsolute Step" + step}>------3</div>
        <div className={"Item PosAbsolute Step" + (3 - step)}>-----4</div>
      </div>
      <button style={{ zIndex: 200 }} onClick={() => changeStep(3 - step)}>
        Change
      </button>
    </div>
  );
};

// useful, right moving button
const Test2 = () => {
  return (
    <div>
      <div className="Padding20 MovingButton Centered">Some label</div>
    </div>
  );
};

export default Test;
