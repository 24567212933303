import SmallArticle from "../../CommonComponents/SmallArticle"
import { useData } from "./utils"
import descriptions from "./labels";

type TibetanWisdomMobileProps = {
  data: ReturnType<typeof useData>
}

const TibetanWisdomMobile: React.FC<TibetanWisdomMobileProps> = props => {
  const { button, pages } = props.data

  const getImageElement = (item: typeof pages[number]) => (
    <div className="PageImage FullWidth">
      <img className="FullWidth" alt="..." src={item.picturePath} />
    </div>
  )

  return <div className="TibetanWisdomMobileBlock FlexV">
    {pages.map((item, ind) => (
      <SmallArticle
        title={item.title}
        article={{
          elementAbove: getImageElement(item),
          text: descriptions[ind]
        }}

        articleClassName="MobileArticleBlock"
      >
        {button(item)}
      </SmallArticle>

    ))}
  </div>
}

export default TibetanWisdomMobile