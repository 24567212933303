import { joinClassNames } from "../../utils";
import "./styles.css";

type BaseLogoProps = {
  logo: string;
  size: number;
  className?: string;
};

const BaseLogo: React.FC<BaseLogoProps> = (props) => {
  const className = joinClassNames("BaseLogo", props.className);

  return (
    <div
      className={className}
      style={{ width: props.size, height: props.size }}
      dangerouslySetInnerHTML={{ __html: props.logo }}
    />
  );
};

export default BaseLogo;
