import { ElementStyle } from "../../../Types";
import { joinClassNames } from "../utils";
import "./styles.css";

type SmallArticleProps = {
  title?: string;
  article: string | {
    elementAbove?: React.ReactElement,
    text: string,
    elementUnder?: React.ReactElement,
  };

  blockClassName?: string;
  blockStyle?: ElementStyle;

  titleClassName?: string;
  titleStyle?: ElementStyle;

  underlinedClassName?: string;
  underlinedStyle?: ElementStyle;

  articleClassName?: string;
  articleStyle?: ElementStyle;

  children?: any
};

const SmallArticle: React.FC<SmallArticleProps> = (props) => {
  const className = joinClassNames(
    props.blockClassName,
    "SmallArticleBlock FlexV"
  );

  const articleContent = typeof props.article === 'string'
    ? { text: props.article }
    : props.article

  return (
    <div className={className} style={props.blockStyle}>
      {props.title && (
        <>
          <div
            className={joinClassNames(
              "ArticleTitle TextUpper",
              props.titleClassName
            )}
            style={props.titleStyle}
          >
            {props.title}
          </div>

          <div
            className={joinClassNames(
              "ArticleUnderlined",
              props.underlinedClassName
            )}
            style={props.underlinedStyle}
          />
        </>
      )}

      {articleContent.elementAbove}

      <div
        className={joinClassNames(
          "ArticleContent FlexV",
          props.articleClassName
        )}
        style={props.articleStyle}
      >
        {articleContent.text.split("%").map((text) => (
          <div>{text}</div>
        ))}
        {articleContent.elementUnder}
      </div>

      {props.children}
    </div>
  );
};

export default SmallArticle;
