import "./styles.css";

import MovingBottom from "../../CommonComponents/Buttons/MovingBottom";
import Logo from "../../CommonComponents/Icons/Logo";

import { labels } from "./utils";
import { joinClassNames, useCustomScroll } from "../../CommonComponents/utils";
import { capitalize } from "../../../Utils/StringUtils";

import { ElementStyle } from "../../../Types";
import { getPicturePath } from "../../../Services/files";

import { useChangePage } from "../utils";
import LandingMobile from "./LandingMobile";

import { isMobile } from "../../../Utils";
import { getLandingImages } from "./utils";
import Footer from "../../CommonComponents/Footer";
import { PageProps } from "../../../Types/others";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIncreaser, useOnce } from "../../../Utils/HooksUtils";
import Globals from "../../../Services/Globals";
import { mapObject } from "../../../Utils/ArrayUtils";

type ImageProps = Omit<
  ReturnType<typeof getLandingImages>[number],
  "link" | "name"
>;
type LandingStatueProps = Omit<ImageProps, "id"> & {
  id: string;
  smallImage?: boolean;
  buttonLink: string;
  content?: any;
  contentWrapperClassName?: string;
  showLogo?: true;
  name?: string;
  className?: any;
};

let StatueBlock: React.FC<LandingStatueProps>;
// let MainStatue: React.FC<{}>, OurTeamElement: React.FC<{}>;

let getLandingBlocks: () => Array<LandingStatueProps>;

const Landing: React.FC<PageProps> = (props) => {
  const mobile = isMobile(props);
  useChangePage("landing");

  useOnce(() => {
    if (mobile) return;

    Globals.changeAppExtraClasses("NoScrollV");
  });

  const statueHeight =
    (!mobile && document.querySelector(".Statue")?.clientHeight) ||
    window.innerHeight;

  const statueBlocks = useMemo(() => (mobile ? [] : getLandingBlocks()), [
    mobile
  ]);

  //////////////////////////////////////////////////////////
  // SCROLL

  const [topElementIndex, changeTopElement] = useIncreaser(-1);
  const checkScrollPossibility = useCallback(
    (direction: -1 | 1) => {
      if (topElementIndex + direction < -1) return false;
      if (topElementIndex + direction > statueBlocks.length) return false;

      return true;
    },
    [statueBlocks.length, topElementIndex]
  );

  const [scrollDirection, changeScrollDirection] = useState<0 | 1 | -1>(0);

  const getScrollingClass = (ind: number) => {
    if (scrollDirection === 1   && ind === topElementIndex)      return "ScrollingUp";
    if (scrollDirection === -1  && ind === topElementIndex - 1)  return "ScrollingDown";
    return "";
  };

  const { onWheelMove, stateChanges } = useCustomScroll(true);
  useEffect(() => {
    if (mobile) return;

    while (!scrollDirection && stateChanges.length) {
      const scroll = stateChanges.shift() === "up" ? 1 : -1;
      if (!checkScrollPossibility(scroll)) continue;

      const needsScrolling = [topElementIndex + scroll, topElementIndex].every(
        (n) => n > -1 && n < statueBlocks.length
      );

      if (!needsScrolling) {
        changeTopElement(scroll);
      } else {
        changeScrollDirection(scroll);
        setTimeout(() => {
          changeScrollDirection(0);
          changeTopElement(scroll);
        }, 950);
      }

      break;
    }
  }, [
    stateChanges,
    statueBlocks,
    scrollDirection,
    topElementIndex,
    changeTopElement,
    checkScrollPossibility,
    mobile
  ]);

  useEffect(() => {
    if (mobile) return;

    mapObject(
      {
        App: topElementIndex < 0 ? 0 : 158,
        LandingBlock: topElementIndex === statueBlocks.length ? statueHeight : 0
      },
      (k, scrollY) => {
        let elem = document.querySelector("." + k)!;
        if (elem.scrollTop !== scrollY) {
          elem.scrollTo({ top: scrollY, left: 0, behavior: "smooth" });
        }
      }
    );
  }, [topElementIndex, mobile, statueBlocks, statueHeight]);

  if (isMobile(props)) {
    return <LandingMobile />;
  }

  const wrapperClassName = joinClassNames(
    "LandingWrapper FullWidth PosRelative",
    scrollDirection && "ScrollState"
  );

  const statuesBlockStyle = {
    top:
      Math.max(Math.min(topElementIndex, statueBlocks.length - 1), 0) *
      statueHeight *
      -1
  };

  return (
    <div className="LandingBlock FullWidth NoScrollV" onWheel={onWheelMove}>
      <div className={wrapperClassName}>
        <div className="StatuesBlock AbsoluteCopy" style={statuesBlockStyle}>
          {statueBlocks.map((props, ind) => (
            <StatueBlock className={getScrollingClass(ind)} {...props} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

/////////////////////////////////////////////////////////
//
const MainStatue: React.FC<{}> = () => {
  return (
    <div className="MainStatueBlock" style={{ letterSpacing: 6 }}>
      <div className="MainLabel" style={{ letterSpacing: 6 }}>
        WHITE LOTUS
      </div>
      <div
        className="MainLabel"
        style={{ marginTop: 10, marginLeft: 1, letterSpacing: 7 }}
      >
        SCULPTURES
      </div>
      <div className="IndividuallyHandcraftedLabelWrapper FlexV FontInter">
        <div>INDIVIDUALLY HANDCRAFTED</div>
        <div>JEWELLED TIBETAN STATUES</div>
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////
//
const OurTeamElement: React.FC<{}> = () => {
  return (
    <>
      <div>OUR TEAM</div>

      <div
        style={{
          fontFamily: "Inter",

          fontSize: 10,
          maxWidth: 400,
          margin: "88px 0 60px 0",
          letterSpacing: 5,
          textTransform: "uppercase"
        }}
      >
        {labels.ourTeamDescription}
      </div>
    </>
  );
};

//////////////////////////////////////////////////////////////////
//
StatueBlock = (incomingProps) => {
  const props = {
    ...incomingProps, // for types detector
    descriptionWidth: 50,
    labelPosition: "right",
    positionH: "center",
    positionV: "center",
    fontSize: 48,
    fontFamily: "Inter",
    letterSpacing: 15,
    buttonLabel: "Learn more",
    opacity: 0.8,
    logoOpacity: 0.4,
    buttonPosition: "under",
    ...incomingProps,
    name: (
      incomingProps.name || (incomingProps.id || "").split("-")[0]
    ).toUpperCase(),
    labelIsLeft: incomingProps.labelPosition === "left",
    marginH: 0
  };

  if (props.className) {
    console.log({ [props.id]: props.className });
  }

  const mainBlockClassName = joinClassNames(
    "Statue FlexH FullWidth PosRelative",
    "Statue" + capitalize(props.labelPosition),
    props.className
  );

  const labelPositionClass = "PositionH-" + capitalize(props.labelPosition);

  const descrPositionVClass = joinClassNames(
    "PositionV-" + capitalize(props.positionV),
    props.positionV === "center" && "JustifyCenter"
  );

  const descrPositionHClass = joinClassNames(
    "PositionH-" + capitalize(props.positionH),
    props.positionH === "center" && "JustifyCenter"
  );

  const content = props.content || <div>{props.name}</div>;

  const button = (
    <MovingBottom
      href={"/" + props.buttonLink}
      label={props.buttonLabel.toUpperCase()}
    />
  );

  const StatueDescrBlockStyle: ElementStyle = {
    position: "absolute",
    top: 0,
    height: "100%",
    left: props.labelIsLeft ? 0 : undefined,
    right: !props.labelIsLeft ? 0 : undefined,
    width: `${props.descriptionWidth}%`
  };

  const StatueLogoBlockStyle = {
    ...StatueDescrBlockStyle,
    top: "10%",
    height: "25%"
  };

  const statueDescrWrapperStyle: ElementStyle = {
    fontSize: props.fontSize,
    letterSpacing: props.letterSpacing
  };

  const statueDescrStyle: ElementStyle = {
    marginLeft: props.positionH === "right" ? undefined : props.marginH,
    marginRight: props.positionH !== "right" ? undefined : props.marginH,
    marginTop: props.positionV === "bottom" ? undefined : props.marginV,
    marginBottom: props.positionV !== "bottom" ? undefined : props.marginV,
    fontFamily: props.fontFamily,
    opacity: props.opacity,
    flexDirection:
      props.buttonPosition === "above" ? "column-reverse" : "column",
    gap: props.buttonGap || 0
  };

  const buttonWrapperStyle = {
    left: 0,
    width: "100%",
    top: props.buttonPosition === "under" ? "100%" : undefined,
    bottom: props.buttonPosition === "above" ? "100%" : undefined,
    paddingLeft: !props.labelIsLeft ? undefined : props.buttonMargin,
    paddingRight: props.labelIsLeft ? undefined : props.buttonMargin
  };

  const imageStyle = {
    left: props.labelIsLeft ? undefined : 0,
    right: !props.labelIsLeft ? undefined : 0,
    width: props.imageWidth
  };

  const contentWrapperClassName = joinClassNames(
    "StatueDescrContentWrapper FlexV",
    props.contentWrapperClassName
  );

  return (
    <div key={props.id} className={mainBlockClassName + " UseTest"}>
      {props.labelIsLeft && <div className="Grow" />}
      <img
        alt="loading"
        src={props.path}
        style={imageStyle}
        className="StatueImage"
      />
      {props.showLogo && (
        <div style={StatueLogoBlockStyle} className="Centered">
          <Logo opacity={props.logoOpacity} size={4} />
        </div>
      )}
      <div
        className={"StatueDescrBlock FlexV PosAbsolute " + descrPositionVClass}
        style={StatueDescrBlockStyle}
      >
        <div className="Empty ConditionallyGrow" />
        <div
          className={"StatueDescrHWrapper FlexH " + descrPositionHClass}
          style={statueDescrWrapperStyle}
        >
          <div className={contentWrapperClassName} style={statueDescrStyle}>
            {content}
            <div
              className={"LandingButtonWrapper FlexH " + labelPositionClass}
              style={buttonWrapperStyle}
            >
              <div className="Empty ConditionallyGrow" />
              {button}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

getLandingBlocks = () => {
  const blocks: LandingStatueProps[] = [];

  const images = getLandingImages();
  blocks.push({
    id: "front-picture",
    path: getPicturePath("pages/landing/main.jpg"),
    smallImage: true,
    buttonLink: "gallery",
    buttonLabel: "VIEW GALLERY",
    positionV: "bottom",
    marginV: 100,
    buttonGap: 40,
    showLogo: true,
    content: <MainStatue />
  });

  blocks.push(
    ...images.map((image) => ({
      smallImage: true,
      buttonLink: "statues/" + image.link,
      showLogo: true as true,
      ...image
    }))
  );

  blocks.push({
    id: "our-team",
    path: getPicturePath("pages/landing/our-team.jpg"),
    smallImage: false,
    buttonLink: "our-team",
    labelPosition: "left",
    fontSize: 64,
    marginV: 100,
    content: <OurTeamElement />
  });

  return blocks;
};

export default Landing;
