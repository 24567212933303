import { useNavigate } from "react-router-dom";

const history = {
  refs: [] as string[]
};

export const addPageToHistory = (ref: string) => {
  if (history.refs.slice(-1)[0] === ref) {
    return;
  }

  history.refs.push(ref);
  if (history.refs.length > 29) {
    const last20 = history.refs.slice(10);
    history.refs.length = 0;
    history.refs.push(...last20);
  }
};

export const getLastPageRef = () => {
  console.log({ history: history.refs });
  const lastRef = history.refs.pop() && history.refs.pop();
  return lastRef || "/";
};

export const useNavigateBack = () => {
  const navigate = useNavigate()
  return () => navigate(getLastPageRef())
}
