import GltfModel from "../../CommonComponents/GltfModel"
import { useData } from "./utils"

type Statue3DMobileProps = {
  data: ReturnType<typeof useData>
}

const Statue3DMobile: React.FC<Statue3DMobileProps> = props => {
  const { statue, apologizeMessage } = props.data
  const modelPath = statue?._3dModelPath

  return <div className="Statue3DMobileBlock">
    {!statue && 'Wrong link !!!'}

    {statue && (modelPath ? (
          <GltfModel path={modelPath} />
        ) : (
          <div
            className="FontInterOnly Centered FlexV ApologizeMessageBlock"
            style={{ fontSize: 22, gap: 10 }}
          >
            {apologizeMessage.split("\n").map((line) => (
              <div>{line}</div>
            ))}
          </div>
        ))}
  </div>
}

export default Statue3DMobile