import { getStatuePicturePaths } from "../files";
import { mapObject, mapObjectValues } from "../../Utils/ArrayUtils";

import { avalokiteshvaraDescr, mahakalaDescr } from "./thangkas";
import { StatueProp } from "./properties";

export type StatueId =
  | "buddha"
  | "avalokiteshvara"
  | "megasambara"
  | "mahakala"
  | "manjushri"
  | "saraswati"
  | "heruka";

export type StatueImage = {
  id: StatueId;
  path: string;
  path2?: string;
  tags?: string[];
};

type StatueSettings = {
  [statueId in StatueId]: {
    name: string;
    description: string;
    mainProps: StatueProp[];

    properties: {
      [Prop in StatueProp]: {
        values: true | string[];
        default?: string;
      };
    };

    imagesExtension?: string; // default jpeg
    images: StatueImage[];

    _3d?: {
      sidePhoto: StatueImage[];
      model: StatueImage[];
    };

    thangka?: {
      image: StatueImage;
      description: string;
    };
  };
};

//////////////////////////////////////////////////////////////////
const createCouples = (
  numbers: (number | string)[],
  ext1: string,
  ext2?: string
) => {
  const couples = [] as string[];
  const getFile = (n: string | number) => {
    const str = n.toString();
    const parts = str.split(".");

    if (parts.length === 1) {
      parts.push(str.length < 3 ? ext1 : ext2 || ext1);
    }

    return parts.join(".");
  };

  for (const n of numbers) {
    if (typeof n === "string") {
      couples.push(getFile(n));
    } else {
      couples.push(getFile(n + 100) + "," + getFile(n));
    }
  }

  return couples;
};

///////////////////////////////////////////////////////////////////////
const statues = {
  //////////////////////////////////////////////
  buddha: {
    name: "buddha",
    description: '-',
    images: {
      _: []
    },

    _3d: {
      sidePhoto: {
        _: ""
      },
      model: {
        _: ""
      }
    }
  },

  //////////////////////////////////////////////
  avalokiteshvara: {
    mainProps: ["position"],
    properties: {
      position: {
        values: ["standing", "seated"]
      },

      material: { values: true }


    },

    images: {
      seated: createCouples(
        ["5", "14", "11", 16, 12, 4, "18", 13, 7, "9"],
        "jpeg",
        "jpg"
      ),
      standing: [8, 2, 1, 4, 9, 7, 10, 6, 3, 5]
    },

    thangka: {
      image: "thangka.png",
      description: avalokiteshvaraDescr
    },

    _3d: {
      sidePhoto: {
        seated: "side3d",
        standing: "side3d"
      },
      model: {
        _: ""
      }
    }
  },

  //////////////////////////////////////////////
  megasambara: {
    mainProps: ["color"],
    properties: {
      color: {
        values: ["blue", "gold"]
      },

      size: {
        values: ["size190", "size120"],
        default: "size190"
      },

      material: { values: true }
    },

    images: {
      blue: createCouples(
        ["8", "10", "5", "9", "3", "7", "4", "1", "2"],
        "jpg"
      ),
      gold: createCouples(
        ["4", 6, 7, "2", "9", 8, 5, "3", 10, "1"],
        "jpeg",
        "jpg"
      )
    },

    thangka: {
      image: "thangka.jpg",
      description: "123"
    },

    _3d: {
      sidePhoto: {
        blue: "side3d",
        gold: "side3d"
      },
      model: {
        _: ""
      }
    }
  },

  //////////////////////////////////////////////
  mahakala: {
    mainProps: ["color"],
    properties: {
      color: {
        values: ["blue", "white"]
      },

      material: { values: true }
    },

    images: {
      blue: createCouples(
        ["7", 1, "3", 2, 8, "9", "5", 6, 10, 4],
        "jpeg",
        "jpg"
      ),
      white: createCouples([9, 3, 2, 1, 8, 10, 4, 6, 7, 6], "jfif", "jpg")
    },

    thangka: {
      image: "thangka.jpeg",
      description: mahakalaDescr
    },

    _3d: {
      sidePhoto: {
        blue: "side3d",
        white: "side3d"
      },
      model: {
        _: ""
      }
    }
  },

  //////////////////////////////////////////////
  manjushri: {
    mainProps: [],
    properties: {
      material: { values: true }
    },
    images: {
      _: createCouples(["5", "7", 9, "10", 8, 4, 3, 2, "6", "1"], "jpeg", "jpg")
    },

    _3d: {
      sidePhoto: {
        _: "side3d"
      },
      model: {
        _: ""
      }
    }
  },

  //////////////////////////////////////////////
  saraswati: {
    mainProps: [],
    properties: {
      size: {
        values: ["size110"]
      },

      material: { values: true }
    },
    imagesExtension: "jpg",
    images: {
      _: createCouples(["1", 14, "2", 6, 8, 11, 13, 7, 9], "jpg")
    },

    _3d: {
      sidePhoto: {
        _: "side3d"
      },
      model: {
        _: ""
      }
    }
  },

  //////////////////////////////////////////////
  heruka: {
    mainProps: [],
    properties: {
      size: {
        values: ["size191", "size140"],
        default: "size191"
      },

      material: { values: true }
    },
    imagesExtension: "jpg",
    images: {
      _: [
        "210,10",
        "108,8",

        "103,3",
        "109,9",

        "101,1",
        "105,5",

        "106,6",
        "107,7",

        "102,2",
        "104,4"
      ]
    },

    _3d: {
      sidePhoto: {
        _: "side3d"
      },
      model: {
        _: ""
      }
    }
  }
};

//////////////////////////////////////////////////////////////////
const convertImages = (
  items: {
    [folder: string]: string | number | (string | number)[];
  },
  statueId: StatueId,
  fileExtension?: string
) => {
  const images: StatueImage[] = [];
  mapObject(items, (folder, files) => {
    if (!files) return;

    const tags = folder
      .toString()
      .split("_")
      .filter(Boolean)
      .map((v) => v.replace("-", "_"));

    const filesArr = Array.isArray(files) ? files : [files];

    for (const path of filesArr) {
      const paths = getStatuePicturePaths(
        [statueId, ...tags].join("-") + "/" + path,
        fileExtension
      );

      images.push({
        id: statueId,
        path: paths.small!,
        path2: paths.big || paths.small,
        tags
      });
    }
  });

  return images;
};

/////////////////////////////////////////////////////////////////////////////
export const statuesSettings = mapObjectValues(statues, (item, statueId) => {
  const images = convertImages(
    item.images,
    statueId,
    "imagesExtension" in item ? item.imagesExtension : undefined
  );

  const _3dImages =
    "_3d" in item ? convertImages(item._3d.model, statueId, "glb") : undefined;

  const _3dSidePhotos =
    "_3d" in item
      ? convertImages(item._3d.sidePhoto, statueId, "jpg")
      : undefined;

  const res = {
    ...item,
    name:         "name"        in item ? item.name : statueId,
    description:  'description' in item ? item.description : '-',
    mainProps:    "mainProps"   in item ? item.mainProps : [],
    properties:   "properties"  in item ? item.properties : {},
    images,

    thangka:
      "thangka" in item
        ? {
            ...item.thangka,
            image: getStatuePicturePaths(
              statueId + "-" + item.thangka.image,
              "png"
            )
          }
        : undefined,

    _3d:
      _3dImages || _3dSidePhotos
        ? {
            model: _3dImages,
            sidePhoto: _3dSidePhotos
          }
        : undefined
  };

  return res as StatueSettings[StatueId];
});
