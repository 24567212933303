import React from "react";

import { useChangePage } from "../utils";
import BaseIcon from "../../CommonComponents/Icons/BaseLogo";

import "./styles.css";
import BaseButton from "../../CommonComponents/Buttons/BaseButton";
import { PageProps } from "../../../Types/others";

import { useContactUsData } from "./data";
import { isMobile } from "../../../Utils";
import ContactUsMobile from "./ContactUsMobile";

type ContactUsProps = PageProps & {
  statue?: {
    id: string;
    options: { [k: string]: string };
  };
};

const ContactUs: React.FC<ContactUsProps> = (props) => {
  const mobile = isMobile(props);
  useChangePage("contactUs");

  const data = useContactUsData();

  if (mobile) return <ContactUsMobile data={data} />;

  return (
    <div className="ContactUsBlock FlexH UseTest_">
      <div className={data.imageClassName}>
        <img className="FullHeight" alt="..." src={data.imageLink} />
      </div>
      <div className="FlexV AlignCenter Grow FontInterOnly FullHeight">
        {data.label.split("\n").map((label) => (
          <div
            className="Centered FullWidth Orange"
            style={{ padding: "0 25px 0 25px" }}
          >
            {label}
          </div>
        ))}

        <div className="ContactLinks FlexV">
          {data.links.map((link) => (
            <BaseButton
              href={link.link}
              variant="rounded light"
              style={{ width: 300, border: `2px solid ${link.borderColor}` }}
            >
              <div className="FlexH FullWidth AlignCenter" style={{ gap: 40 }}>
                <BaseIcon logo={link.logo} size={link.logoSize} />
                <div style={{ fontSize: 20, fontWeight: 600 }}>
                  {link.label}
                </div>
              </div>
            </BaseButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
