import { useOnce } from "../../Utils/HooksUtils";
import Globals from "../../Services/Globals";
import { useLocation } from "react-router-dom";

export const useChangePage = (page: string, addToHistory = true) => {
  const { pathname } = useLocation();

  useOnce(() => {
    Globals.changeMenu({ page });
    Globals.changeAppExtraClasses("");

    // changeMenu({ page });
    if (addToHistory) {
      Globals.addPageToHistory(pathname);
    }

    document.querySelector(".App")?.scroll(0, 0);
  }, true);
};
