export const splitMap = (
  str: string,
  callback: (v: string) => string,
  splitStr = " ",
  joinStr?: string
) => {
  return str
    .split(splitStr)
    .filter(Boolean)
    .map(callback)
    .join(joinStr ?? splitStr);
};

export const isPartOf = <T>(arr1: T[], arr2: T[]) => {
  return arr1.every(item => arr2.includes(item))
}

export const chunk2 = <T>(arr: T[]) => {
  const res = [] as [v1: T, v2: T | undefined][]
  for (let i = 0; i < arr.length; i+=2) {
    res.push([arr[i], arr[i + 1]])
  }

  return res
}

///////////////////////////////////////////////////////////////
// OBJECT UTILS
export const mapObject = <T extends { [key: string]: any }, T2>(
  v: T,
  callback: (key: keyof T, value: T[keyof T]) => T2
) => {
  const values = Object.entries(v) as Array<[key: keyof T, value: T[string]]>
  const pairs = values.map(([key, value]) => callback(key, value));
  return pairs;
};

export const mapObjectValues = <T extends { [key: string]: any }, T2>(
  v: T,
  callback: (value: T[keyof T], key: keyof T) => T2,
  spread = true
) => {
  let obj = {} as {
    [key in keyof T]: T2;
  };

  const values = Object.entries(v) as Array<[key: keyof T, value: T[keyof T]]>

  values.forEach(([key, value]) => {
    obj[key] = callback(value, key);
  });

  if (!spread) {
    obj = Object.assign(v, obj as T);
  }

  return obj;
};
