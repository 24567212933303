import "./styles.css";

import BaseButton from "../../CommonComponents/Buttons/BaseButton";
import GltfModel from "../../CommonComponents/GltfModel";
import { useChangePage } from "../utils";
import { useStatue } from "../../../Services/Statues";
import { useData } from "./utils";
import { PageProps } from "../../../Types/others";
import { isMobile } from "../../../Utils";
import Statue3DMobile from "./Statue3DMobile";

const Statue3D: React.FC<PageProps> = props => {
  const mobile = isMobile(props)
  useChangePage("statueItem3D");

  const data = useData();
  if (mobile) return <Statue3DMobile data={data} />

  const { statue, apologizeMessage } = data

  const sidePhotoPath = statue?._3dSidePhoto;
  const modelPath = statue?._3dModelPath;

  return (
    <div className="_3DStatueBlock FlexH">
      {sidePhotoPath && (
        <div className="SideImageBlock Centered">
          <img alt="..." src={sidePhotoPath} className="SideImage1 FullSize" />
        </div>
      )}
      <div className="CenterImageBlock Grow FlexV FullHeight">
        {modelPath ? (
          <GltfModel path={modelPath} />
        ) : (
          <div
            className="Grow Centered FlexV InterOnly ApologizeMessageBlock"
            style={{ fontSize: 22, gap: 10 }}
          >
            {apologizeMessage.split("\n").map((line) => (
              <div>{line}</div>
            ))}
          </div>
        )}

        {statue?.thangka?.image && (
          <div className="ButtonBlock NoShrink MarginTop20 FlexH">
            <div className="Grow" />
            <div className="PosRelative">
              <BaseButton
                label="?"
                variant="rounded"
                className="LearnMoreButtonSmall PosAbsolute"
                style={{ height: 44, width: 44, marginLeft: 15 }}
              />

              <BaseButton
                href={`/statues/${statue?.id}/thangka`}
                label="LEARN MORE"
                variant="rounded"
                className="LearnMoreButtonBig"
                style={{ width: 220, height: 44, marginLeft: 15 }}
              />
            </div>
          </div>
        )}
      </div>
      {sidePhotoPath && (
        <div className="SideImageBlock Centered">
          <img alt="..." src={sidePhotoPath} className="SideImage2 FullSize" />
        </div>
      )}
    </div>
  );
};

export default Statue3D;
