import React from "react";
import { getPicturePath } from "../../../Services/files";
import "./styles.css";

import { useChangePage } from "../utils";
import descriptions from "./labels";

import MovingBottom from "../../CommonComponents/Buttons/MovingBottom";
import SmallArticle from "../../CommonComponents/SmallArticle";
import { useData } from "./utils";
import { isMobile } from "../../../Utils";
import { PageProps } from "../../../Types/others";
import TibetanWisdomMobile from "./TibetanWisdomMobile";

const TibetanWisdom: React.FC<PageProps> = (props) => {
  const mobile = isMobile(props)
  useChangePage("tibetanWisdom");

  const data = useData()

  if (mobile) return <TibetanWisdomMobile data={data} />

  const { pages, button } = data
  
  return (
    <div
      className="TibetanWisdomBlock FullWidth FontInterOnly FlexV"
      style={{ gap: 30 }}
    >
      {pages.map((item, ind) => (
        <div className="PageBlock FlexH FullWidth" style={{ gap: 90 }}>
          <div className="PageImage" style={{ width: "55%" }}>
            <img className="FullWidth" alt="..." src={item.picturePath} />
          </div>

          <SmallArticle
            title={item.title}
            article={descriptions[ind]}
            blockStyle={{ width: "45%" }}
            articleClassName="Grow AlignCenter"
          >
            {button(item)}
          </SmallArticle>

          {/* <div className="FlexV" style={{ width: "45%" }}>
            <div className="PageTitle">{item.title}</div>
            <div className="Underlined" />
            <div className="PageDescr Grow AlignCenter FlexH">
              {descriptions[ind]}
            </div>
            {button(item)}
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default TibetanWisdom;
