import SplittedText from "../../CommonComponents/SplittedText"
import { ozenDescription } from "./data"
import { useData } from "./utils"

type OurTeamMobileProps = {
  data: ReturnType<typeof useData>
}

const OurTeamMobile: React.FC<OurTeamMobileProps> = props => {
  const { paths } = props.data

  return <div className="OurTeamMobileBlock FontInterOnly">
    <div className="OurTeamMainTitle Centered">
      OUR TEAM
    </div>

    <div className="OzenBlock FlexV AlignCenter">
      <div className="OzenName">
        OZEN RAJNEESH
      </div>

      <div className="OzenImageWrapper">
        <img alt="..." src={paths.ozen} className="FullWidth" />
      </div>

      <div className="OzenDescription">
        <SplittedText text={ozenDescription} />
      </div>
    </div>

    <div className="MobilePeopleBlock MarginTop20 FlexV AlignCenter">
      {paths.people.map(v => (
        <div className="PersonPhotoWrapper MarginTop10">
          <img alt="..." src={v.poster} className="FullWidth" />
        </div>
      ))}
    </div>
  </div>
}

export default OurTeamMobile