import LogoSvg from "./svg2";
import "./styles.css";
import { Link } from "react-router-dom";
import { joinClassNames } from "../../utils";

type LogoProps = {
  className?: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 10;
  opacity?: number;
  href?: string;
};

const Logo: React.FC<LogoProps> = (props) => {
  const mainClassName = "Logo Centered";
  const sizeClass = props.size ? "LogoSize" + props.size : "";
  const opacity = props.opacity || 1;

  const className = joinClassNames(
    mainClassName,
    props.className,
    sizeClass,
    Boolean(props.href) && "IsRef"
  );

  const style = { opacity };

  const elem = (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: LogoSvg }}
      style={style}
    />
  );

  return props.href ? <Link to={props.href}>{elem}</Link> : elem;
};

export default Logo;
