import "./styles.css";

import { getGalleryImages } from "./utils";
import GalleryMobile from "./GalleryMobile";
import { Link } from "react-router-dom";
import AnimatedPictures from "../../CommonComponents/AnimatedPictures";
import { useChangePage } from "../utils";
import { PageProps } from "../../../Types/others";
import { isMobile } from "../../../Utils";

const Gallery: React.FC<PageProps> = (props) => {
  useChangePage("gallery");

  if (isMobile(props)) {
    return <GalleryMobile />;
  }

  const galleryStatueBlock = {
    className: "FlexV GalleryStatueBlock"
  };
  const galleryImageWrapper = {
    className: "GalleryImageWrapper Grow"
  };
  const galleryStatueDescription = {
    className: "GalleryStatueDescription Centered FullWidth MarginTop10"
  };

  const images = getGalleryImages();

  const getImage = (image: typeof images[number]) => {
    return (
      <div {...galleryStatueBlock}>
        <div {...galleryImageWrapper}>
          <img
            className="GalleryImage"
            alt="loading"
            src={image.path}
            style={{ marginTop: `${image.paddingTop || 0}%` }}
          />
        </div>
        <div {...galleryStatueDescription}>
          <Link
            to={`/statues/${image.link}`}
            style={{ border: "1px solid white", borderRadius: "50%" }}
            className="GalleryStatueEye"
          >
            <span role="img">👁️</span>
          </Link>
          <div className="FlexV Red">
            {[image.name, image.description].map((v) => (
              <div className="GalleryStatueLabel">{v.toUpperCase()}</div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <AnimatedPictures
      items={images}
      rowHeight="calc(100vh - 125px)"
      content={getImage}
      listClass="GalleryWrapper"
      rowClass="GalleryLine"
    />
  );
};

export default Gallery;
