import React from "react";
import RadioButtons from "../../Components/CommonComponents/RadioButtons";

const modalCallback = {
  value: (el: React.ReactElement | null) => {}
};

export const setModalCallback = (v: typeof modalCallback["value"]) => {
  modalCallback.value = v;
};

export const openModal = (el: Parameters<typeof modalCallback["value"]>[0]) => {
  modalCallback.value?.(el);
};

export const closeModal = () => {
  openModal(null);
};

export const chooseListValue = <T, T2 = null>(props: {
  values: Array<{ value: T; view: string }>;
  currentValue: T | undefined;
  callback: (value: T, point: { value: T; view: string }) => void;
  label?: string | React.ReactElement;
  _?: T2;
}) => {
  const onChange = (value: T) => {
    const elem = props.values.find((v) => v.value === value);
    props.callback(value, elem!);
    closeModal();
  };

  const element = (
    <RadioButtons
      values={props.values}
      currentValue={props.currentValue}
      label={props.label}
      onChange={onChange}
      className="ModalList"
    />
  );

  openModal(element);
};
