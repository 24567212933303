import BaseLogo from "../../Icons/BaseLogo";
import BaseButton from "../BaseButton";
import imagesLogo from '../../Icons/gallery-icon'
import "./styles.css";

export default () => (
  <BaseButton
    variant="noBorder"
    className="SearchButton"
    href="/gallery"
  >
    <BaseLogo
      logo={imagesLogo}
      size={38}
    />
  </BaseButton>
);
