export const ozenDescription = `
ozen rajneesh is living zen master who created
beautiful eco commune in the mexican jungle near the city Playa del carmen.%

he gathered around him international team of people who is on their spiritual path
of seaking truth and enlightenment... ozen who is the former professional designer
was always interested in tibetian painting... he feels that estetic part of existence...
which consists all the art works like painting sculptures music etc... has the same importance on a spiritual path
as meditation practices... the desert of meditaion has its own beauty and the juice of sheer joy of creativity has its owm - he said%

we are happy to share with world the fruits of our creativity`