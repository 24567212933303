const pagesTitles = [
  `Om mani padme hum is one of the most famous mantras in Mahayana Buddhism, especially in Tibetan Buddhism, the six-syllable mantra of Avalokiteshvara,  the bodhisattva of compassion. The mantra is especially associated with Shadakshari (Lord of the Six Syllables) - the incarnation of Avalokiteshvara and has a deep sacred meaning.
  This mantra has many meanings. All of them come down to explaining the meaning of the totality ...`,

  `The lotus flower or padma is one of the eight auspicious symbols (Skt. astamangala; Tib. bkra shis rtags brgyad) in Buddhism. These include the white parasol, the pair of golden fish, the treasure vase, the lotus flower, the white conch shell, the endless knot, the victory banner, and the eight spoked wheel also known as the dharma wheel. In Chinese Buddhism, the eight auspicious symbols represent the eight internal organs of the Buddha's body. The lotus is identified with the liver of the Buddha ...
  `,

  `Mandala is a sacred schematic representation or design used in Buddhism and Hinduism.% 
  In Buddhism the mandala symbolizes the realm of the deities, the pure land of the Buddhas. It can be said that a mandala is a geometric symbol of a complex structure, which is interpreted as a model of the Universe, a “map of the cosmos”.`
];

export default pagesTitles;

export const pagesContent = {
  lotusFlower: [
    `The lotus flower or padma is one of the eight auspicious symbols (Skt. astamangala; Tib. bkra shis rtags brgyad) in Buddhism. These include the white parasol, the pair of golden fish, the treasure vase, the lotus flower, the white conch shell, the endless knot, the victory banner, and the eight spoked wheel also known as the dharma wheel. In Chinese Buddhism, the eight auspicious symbols represent the eight internal organs of the Buddha's body. The lotus is identified with the liver of the Buddha.%

    Lotus, growing in a muddy water bog, has become a symbol of purity and renunciation. It is identified with beneficial actions performed without the influence of the cycles of birth and death of the human soul. Lotus seats or thrones, on which the deities sit or stand, symbolize their divine origin. Divine souls and beings are born in perfection, possess innate perfection. The lotus nature reflects purity of body, speech and mind. Although deities may appear during karmic cycles, they are not defiled or polluted by the earthly world.%
    
    The Buddhist lotus is depicted with four, eight, sixteen, thirty-two, sixty-four, one hundred eight or one thousand eight petals. The numbers symbolically correspond with the inner lotuses, or chakras of the subtle body, and with the numerical components of the mandala.%
    
    Padmasambhava, or the "lotus-born" tantric master who brought Buddhism to Tibet, is said to have been born from a miraculous lotus that bloomed on Lake Dhanakosha in the western Indian kingdom of Oddiyana. Lotus as a divine womb or female womb is revered as a fertile principle in Buddhist tantra. In Buddhism, the vajra is synonymous with the male organ. The union of the vajra and the lotus is a symbol of the union of form and emptiness, skillful means and wisdom. On the spiritual plane, the union of the vajra and the lotus symbolizes the penetration and ascent of psychic winds through the central channel of the subtle body, which penetrate and open the "lotuses" of the channels-wheels, or chakras.
    `,

    `The lotus became the symbol of Amitabha, the red buddha of the west and the "Lord of Padma, or the lotus family." The qualities of Amitabha are characterized as red fire, vital fluids, evening twilight, summer and the transformation of passion into discriminating awareness. Pandara is revered as the wife of Amitabha. Her attribute is also a red lotus. Amitabha's presiding bodhisattva is Padmapani Avalokiteshvara, the "lotus holder" and bodhisattva of great compassion. Padmapani, which means "lotus-armed", holds an eight-petalled white lotus in his hands as a symbol of his impeccable purity, love and compassion. As emanations of Avalokiteshvara, all fourteen successive incarnations of the Dalai Lama are depicted holding the white lotus of compassion in their right hands. One of the main female bodhisattvas of the lotus family is White Tara. The attribute of Tara in the form of a sixteen-petalled lotus symbolizes the perfection of all her qualities and her likeness to a sixteen-year-old maiden.%

    The thumb and one of the first three fingers on the hand of a Tibetan Buddhist deity or lineage holder are often at the level of the heart. They are folded in a gesture of giving teaching or refuge. In their fingers, they gently hold the lower part of the stem. The stem then rises gracefully, releasing leaves as it approaches the flower at the level of the deity's ear. This symbolizes the nectar-like transmission of the Buddha-dharma, which draws disciples like bees to the pure and unconditioned flavor of oral or "ear-whispered" instructions. The main stem of the lotus is divided into three shoots, one of which ends with a seed box, the stem in the middle ends with a flower, and the third, on the other hand, with a small, unopened bud. These three stages of maturation represent the buddhas of the three times—past, present, and future, respectively.
    `
  ],

  omMani: `
    Om mani padme hum is one of the most famous mantras in Mahayana Buddhism, especially in Tibetan Buddhism, the six-syllable mantra of Avalokiteshvara,  the bodhisattva of compassion. The mantra is especially associated with Shadakshari (Lord of the Six Syllables) - the incarnation of Avalokiteshvara and has a deep sacred meaning.%
    This mantra has many meanings. All of them come down to explaining the meaning of the totality of sacred sounds of its constituent syllables. The mantra itself is rarely interpreted in the sense due to its literal translation is "Oh, the pearl shining in the lotus flower!"%
    The 14th Dalai Lama explains that the mantra represents the purity of the body, speech and mind of the Buddha. The second word ( - "jewel") corresponds to bodhichitta - the desire for awakening, compassion and love. The third word ( - "lotus flower") refers to wisdom. The fourth word ( ) personifies the indivisibility of practice and wisdom.%
    This mantra is traditionally explained by the ability of the six syllables of the mantra to save living beings from birth in the six vales of samsara (hellish worlds, the world of hungry ghosts, the world of animals, the world of people, the world of demigods, the world of the gods), while each syllable symbolizes a certain world.%
    The practice of reading the "Six-syllable" for the purpose of liberating living beings is reflected, in particular, in the Tibetan practice of reciting dharani "The practice of heavenly activity of Avalokiteshvara": OM - removes pride and conceit, MA - removes jealousy and envy, NI - removes attachment and selfish desires, PAD - dispels ignorance and confusion, ME - dissolves greed, HUM - transforms hatred and anger.%
    Another, much more common colloquial designation for this mantra is Mani, by which name it is commonly known and used by Tibetan and Mongolian Buddhists.
  `,

  mandala: `
    Mandala is a sacred schematic representation or design used in Buddhism and Hinduism. 
    In Buddhism the mandala symbolizes the realm of the deities, the pure land of the Buddhas. It can be said that a mandala is a geometric symbol of a complex structure, which is interpreted as a model of the Universe, a “map of the cosmos”.%
    The typical form is an outer circle, inscribed in a square, inscribed in an inner circle, which is often segmented or shaped like a lotus. The outer circle is the Universe, the inner circle is the dimension of deities, bodhisattvas, buddhas. The square between them is oriented to the cardinal points and has T-shaped exits on each side, like a gate to the Universe. The square is divided into 4 parts. The fifth part is the center. Each of the parts has its own color; color is associated with the cardinal points, with one of the buddhas, sense organs, mudras, mantras.%
    The mandala also correlates with calendar and chronological schemes.%
    Mandalas can be either two-dimensional, depicted on a plane, or three-dimensional, embossed. They are embroidered on fabric, painted on sand, made with colored powders and made of metal, stone, wood, and dough. It can even be carved from oil, which is dyed in the appropriate ritual colors. Mandalas are often depicted on the floors, walls and ceilings of temples. The mandala is so sacred in the East that it is drawn to the accompaniment of special rituals and can itself be considered an object of worship.
    Some of the mandalas are made from colored powders for certain ritual practices (for example, in the Kalachakra initiation). By the end of the ritual, the mandala is destroyed.
    Also, according to the mandala scheme, Hindu temples and Vedic altars were erected. Mandalas were used to consecrate Hindu temples, theaters, in Buddhism - for initiation into monks. Chanakya (Kautilya) in the treatise "Arthashastra" mentions the creation of the mandala of the state, which was used for political purposes.
  `
};
