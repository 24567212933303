import "./styles.css";
import { useNavigate } from "react-router-dom";
import BaseButton from "../BaseButton";
import Globals from "../../../../Services/Globals";
import ArrowBackSvg from "./buttonSvg";

const ButtonBack: React.FC<{}> = (props) => {
  const navigateBack = Globals.useNavigateBack();

  return (
    <BaseButton
      className="BackButtonRound"
      variant="circle"
      label="&#8249;"
      onClick={navigateBack}
    />
  );

  // return (
  //   <BaseButton
  //     className="ButtonBack AsLabel"
  //     variant="noBorder"
  //     onClick={moveBack}
  //   >
  //     {/* <div dangerouslySetInnerHTML={{ __html: ArrowBackSvg }} /> */}
  //     &larr;
  //   </BaseButton>
  // );
};

export default ButtonBack;
