import "./styles.css";
import "./styles-transitions.css";

import Landing from "./Components/Pages/Landing";
import StatueItem from "./Components/Pages/StatueItem";
import Statue3D from "./Components/Pages/Statue3D/Statue3d";
import Thangka from "./Components/Pages/Thangka";
import Menu from "./Components/Pages/Menu";
import Gallery from "./Components/Pages/Gallery";
import Cart from "./Components/Pages/Cart";
import OurTeam from "./Components/Pages/OurTeam";
import ContactUs from "./Components/Pages/ContactUs";
import TibetanWisdom from "./Components/Pages/TibetanWisdom";
import LotusFlowerPage from "./Components/Pages/TibetanWisdom/LotusFlower";
import OmManiPage from "./Components/Pages/TibetanWisdom/OmMani";
import Mandala from "./Components/Pages/TibetanWisdom/Mandala";
import Test from "./Components/Pages/Test/Test";

import Modal from "./Components/CommonComponents/Modal";

import { Routes, Route } from "react-router-dom";
import MenuPanel from "./Components/CommonComponents/MenuPanel";

import { useOnce } from "./Utils/HooksUtils";
import Globals from "./Services/Globals";
import { useState } from "react";
import { capitalize } from "./Utils/StringUtils";
import { DesignStyle } from "./Types";

import { getDeviceInfo } from "./Services/DeviceDetect";
import { getUrlParams } from "./Services/htttp";
import { joinClassNames } from "./Components/CommonComponents/utils";

export default function App() {
  const [paramsStr] = useState(window.location.search);
  const params = getUrlParams(paramsStr);

  const [extraClasses, changeExtraClassesOrig] = useState("");
  const changeExtraClasses = (classes: string) => {
    if (classes === extraClasses) return;
    changeExtraClassesOrig(classes);
  };
  Globals.setAppExtraClassesCallback(changeExtraClasses);

  const [design, changeDesign] = useState<DesignStyle>(
    (params.design as "mobile" | "desctop") ||
      (getDeviceInfo().isMobile ? "mobile" : "desctop")
  );

  useOnce(() => {
    document.addEventListener("keydown", (ev) => {
      Globals.onKeyDown(ev.key);
    });
  });

  const appClassName = joinClassNames(
    "App FullWidth PosRelative FontInter ScrollV",
    "App" + capitalize(design),
    extraClasses
  );

  return (
    <div className={appClassName}>
      <MenuPanel page="App" />
      <Routes>
        <Route path="/"                           element={<Landing design={design} />} />
        <Route path="/menu"                       element={<Menu design={design} />} />
        <Route path="/3d_:statueId"               element={<Statue3D design={design} />} />
        <Route path="/statues/:statueId"          element={<StatueItem design={design} />} />

        <Route path="/statues/:statueId/thangka"  element={<Thangka />} />
        <Route path="/gallery"                    element={<Gallery design={design} />} />
        <Route path="/cart"                       element={<Cart />} />
        <Route path="/our-team"                   element={<OurTeam design={design} />} />
        <Route path="/contact-us"                 element={<ContactUs design={design} />} />

        <Route path="/tibetan-wisdom"             element={<TibetanWisdom design={design} />} />
        <Route path="/lotus-flower"               element={<LotusFlowerPage design={design}  />} />
        <Route path="/om-mani-padme-hum"          element={<OmManiPage design={design}  />} />
        <Route path="/mandala"                    element={<Mandala design={design}  />} />

        <Route path="test"                        element={<Test />} />
      </Routes>
      <Modal />
    </div>
  );
}
