import "./styles.css";

import React, { useState } from "react";
import Button from "../../CommonComponents/Buttons/BaseButton";
import { menuPoints } from "./data";

import { useOnce } from "../../../Utils/HooksUtils";
import { joinClassNames } from "../../CommonComponents/utils";
import { useChangePage } from "../utils";
import { PageProps } from "../../../Types/others";
import { isMobile } from "../../../Utils";
import MobileMenu from "./MobileMenu";

/////////////////////////////////////////////////////////
const Menu: React.FC<PageProps> = (props) => {
  const mobile = isMobile(props);
  useChangePage("menu");

  const points = menuPoints;

  const [isFirstCallClass, changeIsFirstCallClass] = useState("IsFirstCall");
  const menuPointBlockClass = joinClassNames(
    "MenuPointBlock FlexH",
    isFirstCallClass
  );

  useOnce(() => {
    changeIsFirstCallClass("");
  }, false);

  if (mobile) return <MobileMenu className={isFirstCallClass} />;

  return (
    <div className="MenuBlock FontInter FullWidth FlexH UseTest">
      <div className="Grow" style={{ minWidth: "60%" }} />
      <div
        className="MenuPart FlexV PosRelative"
        style={{
          width: "40%"
        }}
      >
        {points.map((point) => (
          <>
            <div className={menuPointBlockClass}>
              <div className="Grow" />
              <Button
                className="Top/HoverShow MenuPoint FontInterOnly"
                variant="noBorder"
                href={point.link}
              >
                {point.label.toUpperCase()}
              </Button>
              
                <img className="FullHeight PageScreen" alt="..." src={point.imgPath} />
              
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Menu;
