import { MenuPanelProps } from "../../Types/others";

const menuPropsChangeHolder = {
  value: null as null | ((props: MenuPanelProps) => void)
};

export const changeMenu = (props: MenuPanelProps) => {
  menuPropsChangeHolder.value?.(props);
};
export const setMenuHandler = (
  propsSetter: (props: MenuPanelProps) => void
) => {
  menuPropsChangeHolder.value = propsSetter;
};
