import { useState } from "react";
import Globals from "../../../Services/Globals";
import BaseButton from "../../CommonComponents/Buttons/BaseButton";

const PhotosModal: React.FC<{ paths: string[]; ind: number }> = (props) => {
  const [currInd, changeInd] = useState(props.ind);
  const changeIndex = (shift: 1 | -1) => () => {
    const newInd = currInd + shift;
    if (props.paths[newInd]) changeInd(newInd);
  };

  const BaseButtonProps = {
    variant: "noBorder",
    className: "StatueModalNavigationButton"
  };

  const sideNavigationProps = {
    className: "SideNavigationBlock Padding20 Centered NoShrink",
    style: { minWidth: 100 }
  };

  const navigationButtons = [
    { visible: Boolean(currInd), label: "‹" },
    { visible: currInd + 1 < props.paths.length, label: "›" }
  ].map((v, ind) => {
    if (!v.visible) return null;

    return (
      <div {...sideNavigationProps}>
        <BaseButton
          {...BaseButtonProps}
          label={v.label}
          onClick={changeIndex(ind ? 1 : -1)}
        />
      </div>
    );
  });

  return (
    <div className="FullSize FlexH" onClick={(e) => e.stopPropagation()}>
      {navigationButtons[0]}
      <div
        className="PhotoBackground Grow Centered FullHeight"
        onClick={() => Globals.closeModal()}
      >
        <div className="PhotoWrapper FullHeight" style={{ overflow: "hidden" }}>
          <img
            key={currInd}
            src={props.paths[currInd]}
            alt="loading"
            className="FullHeight"
          />
        </div>
      </div>
      {navigationButtons[1]}
    </div>
  );
};

export default PhotosModal;
