import { changeMenu, setMenuHandler } from "./menuPanel";
import {
  onKeyPress,
  setOnKeyPressHandler,
  onKeyDown,
  setOnKeyDownHandler
} from "./events";
import { addPageToHistory, getLastPageRef, useNavigateBack } from "./history";
import { setAppExtraClassesCallback, changeAppExtraClasses } from "./app";
import {
  openModal,
  closeModal,
  setModalCallback,
  chooseListValue
} from "./modal";

const Globals = {
  changeMenu,
  setMenuHandler,

  /////////////////////////
  onKeyPress,
  setOnKeyPressHandler,
  onKeyDown,
  setOnKeyDownHandler,

  //////////////////////////
  addPageToHistory,
  getLastPageRef,
  useNavigateBack,

  //////////////////////////
  setModalCallback,
  openModal,
  closeModal,
  chooseListValue,

  //////////////////////////
  setAppExtraClassesCallback,
  changeAppExtraClasses
};

export default Globals;
