import { Link } from "react-router-dom";
import { joinClassNames } from "../../utils";
import "./styles.css";

type TwoLinesProps = {
  className?: string;
  href?: string;
  form?: "cross";
};

const TwoLines: React.FC<TwoLinesProps> = (props) => {
  const mainClassName = joinClassNames(
    "TwoLinesMenu CursorPointer FlexV AlignCenter",
    props.form === "cross" ? "Cross" : ""
  );

  const href = props.href || "/menu";

  return (
    <Link to={href} className={mainClassName}>
      <div className="TwoLinesLine" />
      <div className="TwoLinesLine" />
    </Link>
  );
};

export default TwoLines;
