import { joinClassNames } from "../utils";
type SplittedTextProps = {
  text: string;
  delimiter?: string;
  className?: string | ((ind?: number) => string);
  paddingTop?: number;
};

const defaultPaddingTop = 5;

const SplittedText: React.FC<SplittedTextProps> = (props) => {
  const delimiter = props.delimiter ?? "%";
  const paddingTop = props.paddingTop || defaultPaddingTop;

  const getClassName = (ind: number) => {
    const extraClasses =
      (typeof props.className === "string" && props.className) ||
      (typeof props.className === "function" && props.className(ind)) ||
      "";

    return joinClassNames("SplittedPart", extraClasses);
  };

  const getStyle = (ind: number) => {
    if (ind)
      return {
        paddingTop
      };

    return {};
  };

  return (
    <>
      {props.text.split(delimiter).map((text, ind) => (
        <div className={getClassName(ind)} style={getStyle(ind)}>
          {text}
        </div>
      ))}
    </>
  );
};

export default SplittedText;
