import { useState } from "react";
import Globals from "../../Services/Globals";
import { splitMap } from "../../Utils/ArrayUtils";
import { useConst, useOnce } from "../../Utils/HooksUtils";
import { capitalize } from "../../Utils/StringUtils";

type VerticalDirection = 'up' | 'down'

/////////////////////////////////////////////////////////
export const getStyleClassName = (cl?: string, prefix = "Style") =>
  splitMap(cl || "", (cl) => prefix + capitalize(cl));

///////////////////////////////////////////////////////////
export const joinClassNames = (...classNames: any[]) =>
  classNames
    .filter((v) => Boolean(v) && typeof v === "string")
    .join(" ")
    .trim();

///////////////////////////////////////////////////////////
export const useCustomScroll = (
  preventDefault?: true | ((direction: VerticalDirection) => void),
  maxScrollsInQueue?: number
) => {
  const [stateChanges, changeStateChanges] = useState<Array<VerticalDirection>>([]);
  const lastTimestamp = useConst({ value: performance.now() });

  const maxScrolls = maxScrollsInQueue || 1

  ///////////////////////////////////////////////////////////////////
  const addScroll = (v: typeof stateChanges[number]) => {
    const currTimestamp = performance.now();
    if (currTimestamp - lastTimestamp.value < 200) return;

    if (maxScrolls === stateChanges.length) return

    lastTimestamp.value = currTimestamp;
    changeStateChanges([...stateChanges, v]);
  };

  ////////////////////////////////////////////////////////////////////
  const onWheelMove = (e: React.WheelEvent<HTMLDivElement>) => {
    const direction: VerticalDirection = e.deltaY > 0 ? "up" : "down";
    addScroll(direction);

    // doesn't work
    if (
      preventDefault &&
      (preventDefault === true || preventDefault(direction))
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  useOnce(() => {
    Globals.setOnKeyDownHandler((code) => {
      if (code === "ArrowUp") {
        addScroll("down");
      } else if (code === "ArrowDown") {
        addScroll("up");
      }
    });
  });

  ////////////////////////////////////////////////////////////////////////
  return { stateChanges, onWheelMove };
};
