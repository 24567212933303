import React from "react";
import { joinClassNames } from "../../utils";
import BaseButton from "../BaseButton";
import "./styles.css";

type CartButtonProps = {
  hoverBlock?: JSX.Element;
};

const CartButton: React.FC<CartButtonProps> = (props) => {
  const productsCount = 2;
  const className = joinClassNames(
    "CartBlock PosRelative",
    productsCount ? "HasProducts" : ""
  );

  return (
    <div className={className}>
      <BaseButton href="/cart" className="CartButton">
        &#128722;
      </BaseButton>
      <div
        className={
          "CartCount Centered PosAbsolute ProductsCount" + productsCount
        }
        style={{
          left: 0,
          top: "70%",
          backgroundColor: "white",
          color: "black",
          borderRadius: 50,
          width: 20
        }}
      >
        {productsCount}
      </div>
      {props.hoverBlock || null}
    </div>
  );
};

export default CartButton;
