import { useState, useEffect, useMemo } from "react";
import { StatueId, statuesSettings } from "./data";
import { StatueProp, propHasValue, getValues, getView, getProperty } from "./properties";

import { useConst, useOnce } from "../../Utils/HooksUtils";
import { isPartOf, mapObject } from "../../Utils/ArrayUtils";
import { useNavigate, useParams } from "react-router-dom";

export type StatueOptions = {
  property: StatueProp;
  values: {
    value: string;
    view: string;
  }[];
}[];

const checkIsStatue = (id: string): id is StatueId => {
  return id in statuesSettings;
};

export const useStatue = (slug?: string) => {
  const params = useParams();
  const fullPath = slug || params.statueId || ""

  const navigate = useNavigate()

  const [statueId, ...filterValues] = fullPath.split("-");
  const isStatue = checkIsStatue(statueId);

  const settings: typeof statuesSettings[StatueId] =
    statuesSettings[statueId as StatueId];

  const [currentPath, changeCurrentPath] = useState('')
  const onPathChange = useConst({ value: (v: string) => {} })

  const [chosenOptions, changeChosenOptions] = useState(
    {} as { [key: string]: string }
  );

  const tagsFilter = useMemo(() => 
    isStatue 
      ? settings.mainProps
          .map((prop) => chosenOptions[prop])
          .filter(Boolean) 
      : []
  , [chosenOptions])

  const propsPath = statueId + '-' + tagsFilter

  useEffect(() => {
    if (!isStatue) return 

    if (propsPath !== currentPath) {
      changeCurrentPath(propsPath)

      if (currentPath) {
        onPathChange.value(propsPath)
      }
    }
  }, [propsPath])

  const changeOption = (prop: string, value: string) => {
    changeChosenOptions({
      ...chosenOptions,
      [prop]: value
    });
  };

  useOnce(() => {
    if (!isStatue) return;

    filterValues.forEach((value) => {
      const prop = settings.mainProps.find((prop) => propHasValue(prop, value));
      if (prop) chosenOptions[prop] = value;
    });
  }, true);

  const options = useConst({ value: [] as StatueOptions });

  if (!isStatue) return null;

  return {
    id: statueId,
    get name() {
      return settings.name;
    },

    settings,

    get options() {
      if (options.value.length) return options.value;

      mapObject(settings.properties, (property, v) => {
        const valuesSet = v.values === true ? getValues(property) : v.values;
        const values = valuesSet.map((value) => ({
          value,
          view: getView(property, value)
        }));

        options.value.push({ property, values });

        const defaultValue = !(property in chosenOptions) 
          && (v.default || getProperty(property).defaultValue)

        if (defaultValue) {
          changeOption(property, defaultValue);
        } else if (values.length === 1) {
          changeOption(property, values[0].value)
        }
      });

      return options.value;
    },

    getChosenValue(
      option: {
        property: StatueProp;
        values: { value: string; view: string }[];
      },
      onEmpty = "..."
    ) {
      const value = chosenOptions[option.property];
      if (!value)
        return {
          value: null,
          view: onEmpty
        };

      return {
        value,
        view: option.values.find((v) => v.value === value)!.view
      };
    },

    get tagsFilter() {
      return tagsFilter
    },

    get propsPath () {
      return propsPath
    },

    get imagesPaths() {
      return settings.images.filter((image) => {
        console.log({ tagsFilter, imageTags: image.tags })
        return isPartOf(tagsFilter, image.tags || [])
      });
    },

    get _3dModelPath() {
      const path = settings._3d?.model.find((model) =>
        isPartOf(tagsFilter, model.tags || [])
      )?.path;

      return path;
    },

    get _3dSidePhoto() {
      const path = settings._3d?.sidePhoto.find((item) =>
        isPartOf(this.tagsFilter, item.tags || [])
      )?.path;

      return path;
    },

    get _3dLink () {
      const link = '/3d_' + propsPath
      return link
    },

    getPaths(second = false) {
      if (!second) return this.imagesPaths.map((v) => v.path);
      return this.imagesPaths.map((v) => v.path2 || v.path);
    },

    navigate (path: string) {
      navigate(path)
    },

    get contactUsLink() {
      return (
        `/contact-us?statue=${this.id}` +
        mapObject(chosenOptions, (k, v) => `&${k}=${v}`).join("")
      );
    },

    get thangka() {
      return settings.thangka;
    },

    get chosenOptionsString() {
      return mapObject(chosenOptions, (p, v) => getView(p as string, v)).join(
        ", "
      );
    },

    setOnPathChange (callback: (path: string) => void) {
      onPathChange.value = callback
    },

    chosenOptions,
    changeOption,
    changeChosenOptions
  };
};

export type StatueData = ReturnType<typeof useStatue>;
export type StatueDataExisting = NonNullable<StatueData>;
