
import { PageProps } from "../../../../Types/others";
import { isMobile } from "../../../../Utils";
import { useChangePage } from "../../utils";
import { pagesContent } from "../labels";
import WisdomPage from "../WisdomPage";
import "./styles.css";

const Mandala: React.FC<PageProps> = (props) => {
  const mobile = isMobile(props)
  useChangePage("Mandala");

  return (
    <WisdomPage
      title="Mandala"
      article={pagesContent.mandala}
      imageFileName="mandala.jpg"
      className="MandalaPage"
      mobile={Boolean(mobile)}
    ></WisdomPage>
  );
};

export default Mandala;
