import { Dict } from "../Types";

export const getUrlParams = (path: string) => {
  const paramsStr = path.split("?")[1];
  if (!paramsStr) return {};

  const res = {} as Dict<string>;

  for (const part of paramsStr.split("&")) {
    const [key, value] = part.split("=");
    res[key] = value;
  }

  return res;
};
