type MoveParams = {
  left: number;
  right: number;
  up: number;
  down: number;
};

type TouchEvent = React.TouchEvent;

export const createTouchEvents = (p: {
  minMove?: number;
  onlyOneDirection?: boolean;
  onStart?: (e: TouchEvent) => void;
  onMove?: (p: MoveParams) => void;
  onEnd?: (p: MoveParams) => void;
}) => {
  const minMove = p.minMove || 10;
  const onlyOneDirection = p.onlyOneDirection ?? true;

  const currTouch = { x: 0, y: 0, active: false };
  const lastTouch = { x: 0, y: 0, active: false };

  const getMoveParams = (
    t1: { x: number; y: number },
    t2: { x: number; y: number }
  ) => {
    const getDiff = (attr: "x" | "y") => {
      const diff = t1[attr] - t2[attr];
      if (Math.abs(diff) < minMove) return 0;

      return diff;
    };

    const moveParams = {
      left: -Math.min(0, getDiff("x")),
      right: Math.max(0, getDiff("x")),
      up: -Math.min(0, getDiff("y")),
      down: Math.max(0, getDiff("y"))
    };

    if (!Object.keys(moveParams).some((k) => moveParams[k as keyof MoveParams]))
      return null;

    if (onlyOneDirection) {
      if (
        Math.max(moveParams.left, moveParams.right) >
        Math.max(moveParams.up, moveParams.down)
      ) {
        moveParams.up = moveParams.down = 0;
      } else {
        moveParams.left = moveParams.right = 0;
      }
    }

    return moveParams;
  };

  const createMoveParams = (touch: React.Touch) => {
    lastTouch.x = touch.clientX;
    lastTouch.y = touch.clientY;

    const moveParams = getMoveParams(lastTouch, currTouch);
    return moveParams;
  };

  const onTouchStart = (e: TouchEvent) => {
    currTouch.active = true;
    currTouch.x = e.touches[0].clientX;
    currTouch.y = e.touches[0].clientY;

    lastTouch.active = false;
    lastTouch.x = lastTouch.y = 0;
  };

  const onTouchMove = (e: TouchEvent) => {
    if (!currTouch.active) return;

    const moveParams = createMoveParams(e.touches[0]);
    //alert(JSON.stringify(moveParams))
    if (!moveParams || !p.onMove) return;

    p.onMove(moveParams);
  };

  const onTouchEnd = (e: TouchEvent) => {
    currTouch.active = false;

    const moveParams = getMoveParams(lastTouch, currTouch);
    //alert(JSON.stringify(moveParams));
    if (moveParams && p.onEnd) p.onEnd(moveParams);
  };

  return {
    events: { onTouchStart, onTouchMove, onTouchEnd }
  };
};
