import "./styles.css";

import Logo from "../../CommonComponents/Icons/Logo";
import Eye from "../../CommonComponents/Icons/Eye";

import { joinClassNames } from "../../CommonComponents/utils";
import AnimatedPictures from "../../CommonComponents/AnimatedPictures";
import { Dict } from "../../../Types";
import React, { useState } from "react";
import { useChangePage } from "../utils";
import PhotosModal from "./PhotosModal";
import Globals from "../../../Services/Globals";
import { StatueOptions, useStatue } from "../../../Services/Statues";
import {
  getProperty,
  StatueProp,
  statuesProperties
} from "../../../Services/Statues/properties";
import { StatueImage } from "../../../Services/Statues/data";
import { PageProps } from "../../../Types/others";
import { isMobile } from "../../../Utils";

import ThreeDModelButton from "./Buttons/ThreeDModelButton";
import ConnectUsButton from "./Buttons/ConnectUsButton";

import StatueItemMobile from "./StatueItemMobile";
import { useNavigate } from "react-router-dom";

//////////////////////////////////////////////////////
const StatueItem: React.FC<PageProps> = (props) => {
  useChangePage("statueItem");
  const statue = useStatue();

  statue?.setOnPathChange(path => {
    statue.navigate('/statues/' + path)
  })

  if (isMobile(props) && statue) {
    return <StatueItemMobile statue={statue} />;
  }

  if (!statue) return null;

  /////////////////////////////////////////////////////
  const openModal = (paths: StatueImage) => () => {
    Globals.openModal(
      <PhotosModal
        paths={statue.getPaths(true)}
        ind={statue.imagesPaths.indexOf(paths)}
      />
    );
  };

  ////////////////////////////////////////////////////
  const getImage = (paths: StatueImage) => {
    return (
      <div className="ImgWrapper PosRelative" style={{ padding: 5 }}>
        <div className="EyeWrapper PosAbsolute" style={{ right: 0, padding: 3 }}>
          <Eye
            className="ItemEyeIcon CursorPointer"
            onClick={openModal(paths)}
          />
        </div>
        <img className="FullSize" src={paths.path} alt="loading" />
      </div>
    );
  };

  ///////////////////////////////////////////////
  const itemLabelClass = joinClassNames(
    "ItemLabel",
    "ItemLabel_" + statue.id,
    "ItemLabel_Length" + Math.ceil(statue.name.length / 2) * 2
  );

  ///////////////////////////////////////////////////
  return (
    <div className="StatueItemBlock FlexH FullWidth UseTest_">
      <AnimatedPictures
        key={statue.propsPath}
        items={statue.imagesPaths}
        rowHeight="calc(100vh - 128px)"
        itemInRow={2}
        content={getImage}
        listClass="ItemGalleryWrapper Grow"
        rowClass="ItemGalleryLine"
      />

      <div className="RightBlock FlexH FullHeight">
        <div className="PropertiesBlock FlexV AlignCenter ScrollV Fullheight">
          <ThreeDModelButton
            className="Top/FullWidth Top/NoShrink"
            statue={statue}
          />

          <Logo className="ItemLogo" size={3} opacity={0.4} />
          <Options
            options={statue.options}
            chosenOptions={statue.chosenOptions}
            changeOption={statue.changeOption}
          />

          <ConnectUsButton
            className="MarginTop20 Top/FullWidth Top/NoShrink"
            statue={statue}
          />
        </div>

        <div className="ItemLabelBlock FlexV">
          <div className={itemLabelClass}>{statue.name}</div>
        </div>
      </div>
    </div>
  );
};

///////////////////////////////////////////////////////////
//

const Options = (props: {
  options: StatueOptions;
  chosenOptions: Dict<string>;
  changeOption: (prop: string, value: string) => void;
}) => {
  const [openPropertyId, changeOpenProperty] = useState<string | null>(null);
  const changeOpenPropertyId = (id: string) => {
    changeOpenProperty(openPropertyId === id ? null : id);
  };

  const changeOption = (propertyId: StatueProp, value: string) => {
    props.changeOption(propertyId, value);
    changeOpenProperty(null);
  };

  const getOptionView = (key: StatueProp) => {
    if (!(key in props.chosenOptions)) return "<...>";
    return getProperty(key).view(props.chosenOptions[key]);
  };

  return (
    <div className="ItemProperties FlexV FullWidth">
      {props.options.map(({ property, values }) => (
        <div>
          <div className="OptionLine FlexH FullWidth TextUpper">
            <div className="OptionKey Grow">{property}</div>
            <div
              className="OptionValue CursorPointer"
              onClick={() => {
                changeOpenPropertyId(property);
              }}
            >
              {getOptionView(property)}
            </div>
            <div
              className="OpenControl CursorPointer MarginLeft5"
              onClick={() => {
                changeOpenPropertyId(property);
              }}
            >
              {property === openPropertyId ? "▴" : "▾"}
            </div>
          </div>
          <div
            className={joinClassNames(
              "OptionLineValues",
              property === openPropertyId && "OpenValues"
            )}
          >
            {values.map((value) => (
              <div className="FlexH">
                <div className="Grow" />
                <div
                  className="OptionLineValue FlexH CursorPointer"
                  onClick={() => changeOption(property, value.value)}
                >
                  <div className="ValueChecked">
                    {props.chosenOptions[property] === value.value ? "✓" : ""}
                  </div>
                  <div className="ValueLabel">{value.view}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatueItem;
