import "./styles.css";
import eyeVector from "../eye-border";
import React from "react";
import { ElementStyle } from "../../../../Types";
import { joinClassNames } from "../../utils";

type EyeProps = {
  className?: string;
  style?: ElementStyle;
  onClick?: () => void;
};

const Eye: React.FC<EyeProps> = (props) => {
  const blockClassName = joinClassNames(
    "EyeBlock PosRelative",
    props.className
  );

  return (
    <div className={blockClassName} style={props.style} onClick={props.onClick}>
      <div
        className="EyeBorderContainer AbsoluteCopy Centered"
        dangerouslySetInnerHTML={{ __html: eyeVector }}
      />
      <div className="EyeContainer AbsoluteCopy Centered">
        <div className="Eye" />
      </div>
    </div>
  );
};

export default Eye;
